.ubermenu-main {
  box-shadow: 0 5px 5px rgba($color: #000000, $alpha: 0.05) !important;
  .ubermenu-nav {
    > .ubermenu-item {
      > .ubermenu-target {
        &:hover {
          background-color: $color-principal-light;
        }
        > .ubermenu-target-title {
          font-family: $typo-condensed;
          // text-transform: uppercase;
          font-size: 1rem;
          font-weight: 500;
        }
      }
    }
  }
  .ubermenu-submenu {
    .ubermenu-item {
      .ubermenu-target {
        &:hover {
          background-color: $color-principal-light;
        }
      }
    }
  }
}

.nav-promo {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  margin-left: 2rem;
  @media screen and (max-width: 1199px) {
    margin-left: 1rem;
    flex-wrap: wrap;
  }

  .menu-item {
    a {
      padding: 5px 10px;
      font-family: $typo-condensed;
      font-size: 1rem;
      @media screen and (max-width: 1199px) {
        font-size: 0.9rem;
      }
      font-weight: medium;
    }
  }
}

.shiftnav-toggle {
  background: initial !important;
  padding: initial !important;
  color: $color-gris-04 !important;
  box-shadow: none !important;
  font-size: 1.5rem;
}

.shiftnav .shiftnav-menu-image img {
  max-width: 50% !important;
  height: auto;
}

.header-cat {
  margin-bottom: 3rem;
  @media screen and (max-width: 767px) {
    margin-bottom: 1.5rem;
  }
  .container {
    @media screen and (max-width: 575px) {
      padding-right: 0;
      padding-left: 0;
    }
  }
  &__container {
    display: flex;
    overflow-x: auto;
    overflow-scrolling: touch;
    @media screen and (max-width: 767px) {
      overflow-x: scroll;
    }
  }
  &__item {
    text-align: center;
    width: 120px;
    margin-right: 2rem;
    &:first-child {
      @media screen and (max-width: 767px) {
        margin-left: 1rem;
      }
    }
    &:last-child {
      @media screen and (max-width: 767px) {
        padding-right: 1rem;
      }
    }
  }
  &__figure {
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: inline-block;
    border-radius: 1rem;
    overflow: hidden;
    margin-bottom: 0.5rem;
  }
  &__img {
    width: 90px;
    height: 90px;
    object-fit: cover;
    @media screen and (max-width: 575px) {
      width: 80px;
      height: 80px;
    }
  }
  &__title {
    font-size: $font-size-small;
    font-weight: 500;
  }
}

// .m-overlay {
//   display: none;
//   position: fixed;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   background-color: #fff;
//   padding: 3rem;
//   z-index: 1000;
//   overflow-y: scroll;
//   overscroll-behavior: contain;
//   @media screen and (max-width: 575px) {
//     padding: 1rem;
//   }
//   &__content {
//     display: flex;
//     flex-direction: column;
//     height: 100%;
//     @media screen and (max-width: 575px) {
//       padding: 0px 12px;
//     }
//     @media screen and (max-width: 375px) {
//     }
//   }
//   &__header {
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;
//   }
//   &__logo {
//     margin: 1rem 0 1rem 0;
//     img {
//       width: 250px;
//       @media screen and (max-width: 575px) {
//         width: 146px;
//       }
//     }
//   }
//   &__close {
//     .bi {
//       font-size: 2.5rem;
//     }
//   }
//   &__menu {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 100%;
//     position: relative;
//     margin-top: 1rem;
//     margin-bottom: 1rem;
//   }
//   &__nav {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: center;
//     text-align: center;
//     padding-left: 0;
//     list-style: none;
//     margin-bottom: 2rem;
//     @media screen and (max-width: 575px) {
//       margin-bottom: 2rem;
//     }
//     @media screen and (max-width: 375px) {
//       margin-bottom: 1rem;
//     }

//     li {
//       a {
//         color: $color-gris-02;
//         text-decoration: none;
//         font-size: 1rem;
//         font-weight: 500;
//         padding: 5px 10px;
//       }
//     }
//   }
//   &__categorias {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     padding: 1rem;
//     margin-bottom: 1rem;
//     border-bottom: 1px dashed $color-gris-02;
//     .widget__title {
//       text-align: center;
//       text-transform: uppercase;
//       font-weight: normal;
//       letter-spacing: 1px;
//       font-size: $font-size-small;
//     }
//     ul {
//       list-style: none;
//       padding-left: 0px;
//       text-align: center;
//       display: flex;
//       flex-wrap: wrap;
//       justify-content: center;
//       flex-direction: column;
//       li {
//         a {
//           // color: $color-background;
//           font-size: 1.7rem;
//           padding: 8px 5px;
//           font-weight: $font-weight-bold;
//           &:hover {
//           }
//           @media screen and (max-width: 360px) {
//             font-size: 1.4rem;
//           }
//         }
//       }
//     }
//   }
//   &__lang {
//     text-align: center;
//     margin-bottom: 2rem;
//   }
//   &__actions {
//     display: flex;
//     flex-direction: column;
//     margin-bottom: 5rem;
//     padding-bottom: 5rem;
//     align-items: center;
//   }
// }

// .m-overlay .container {
//   @media screen and (max-width: 575px) {
//     padding: 0px;
//   }
//   @media screen and (max-width: 375px) {
//     padding: 0px;
//   }
// }
