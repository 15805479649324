/* SCSS Botones
--------------------------------------------------- */

.link-underline {
  text-decoration: underline;
}

.boton {
  font-size: 0.9rem;
  background-color: $color-gris-04;
  color: $color-background;
  font-weight: $font-weight-medium;
  @include input-buttons;
  &:hover {
    @include input-buttons-hover;
    background-color: $color-gris-04;
  }
  .bi {
    display: inline-flex;
    align-items: center;
  }
}

.boton--whatsapp,
.boton--telegram {
  .bi {
    margin-right: 10px;
  }
}

.boton--whatsapp {
  background-color: #075e54 !important;
  color: #25d366;
  &:hover {
    background-color: #075e54 !important;
    color: #25d366;
  }
}

.boton--blanco {
  background-color: $color-background !important;
  color: $color-gris-04 !important;

  &:hover {
    background-color: $color-background !important;
    color: $color-gris-04;
  }
}

.boton--principal {
  background-color: $color-principal !important;
  color: $color-background !important;

  &:hover {
    background-color: $color-principal !important;
    color: $color-background;
  }
}

.boton--outline-blanco {
  @include input-buttons;
  background-color: initial !important;
  color: $color-background;
  border-color: $color-background;
  border-style: solid;
  &:hover {
    background-color: $color-background !important;
    color: $color-gris-04;
  }
}

.boton--lg {
  @include input-buttons-lg;
}

.boton--sm {
  @include input-buttons-sm;
}

.boton--block {
  text-align: center;
  display: block;
  width: 100%;
}
