textarea,
select,
input,
.qty {
  padding: 12px 12px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 16px;
  color: $color-gris-03;
  // box-shadow: inset 1px 1px 3px 0px rgba(0, 0, 0, 0.15);
  @media (max-width: 575px) {
    font-size: $font-size-smaller;
    padding: 11px 11px;
  }
}


.qty {
  text-align: center;
  width: 80px;
}

.shop_table .qty {
  width: 70px;
}

select {

  background: #fff url('../assets/ico/ico-arrow-down-pic.svg') no-repeat right center;
  background-size: 18px auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-position: right 15px top 10px;
  cursor: pointer;
  /* Hide default dropdown arrow */
  appearance: none;
  transition: $trans;
  padding-right: 2rem !important;
  font-size: $font-size-small;

  &.orderby {
    padding: 8px 10px;
    // background-position: right 10px top 7px;
    box-shadow: none;
  }

  &:hover {
    color: $color-principal-dark;
    transition: $trans;
  }
}



.selection {
  .select2-selection {
    height: 50px !important;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    box-shadow: inset 1px 1px 3px 0px rgba(0, 0, 0, 0.15);
  }

  .select2-selection__rendered {
    padding: 10px 14px;
  }

  .select2-selection__arrow {
    padding: 10px 12px;
    margin-top: 12px;
    margin-right: 5px;
  }

  .select2-selection__clear {
    margin-right: 1rem;
  }

  .select2-selection__arrow b {
    border-width: 10px 10px 0 10px;
  }
}


.qib-container {
  .minus {
    border-radius: 4px 0 0 4px !important;
  }
  .plus {
     border-radius: 0 4px 4px 0 !important;
  }
}