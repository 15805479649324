.wpcf7 {
  margin: 1.5rem 0;

  .form-group {
    margin-bottom: 1rem;
  }

  .form-control {
    width: 100%;
  }

  .wpcf7-not-valid-tip {
    font-size: 12px;
    color: red;
  }
}

.wpcf7-acceptance {
  font-size: 0.7rem;
  color: $color-gris-02;
  color: rgba(0, 0, 0, 0.4);

  .wpcf7-list-item {
    // margin-left: 0;
  }
}

.wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.5em 1em;
  border-radius: 8px;
}

.wpcf7-mail-sent-ok {
  border: 2px solid #398f14;
  background-color: #398f14;
  color: $color-background;
}

.wpcf7-mail-sent-ng {
  border: 2px solid #ff0000;
  background-color: #ff0000;
  color: $color-background;
}

.wpcf7-spam-blocked {
  border: 2px solid #ffa500;
  background-color: #ffa500;
}

.wpcf7-acceptance-missig {
  border: 2px solid #ffa500 !important;
  background-color: #ffa500 !important;
}

.woocommerce-product-search {
  position: relative;
  .search-field {
    background-color: $color-gris-01;
    border: none;
    font-size: 0.85rem;
    padding-right: 3rem;
    width: 300px;
  }
  button[type="submit"] {
    margin-left: -5rem;
    background-color: $color-gris-02;
    border: none;
    color: $color-background;
    border-radius: 5px;
    padding: 3px 10px;
  }
}

.header__right {
  .woocommerce-product-search {
    position: relative;
    .search-field {
      background-color: $color-gris-01;
      background: #eaf4f2;
      color: $color-grey-1;
      border: none;
      border: 1px solid #d2dad8;
      font-size: $font-size;
      padding-right: 3rem;
      width: 500px;
      border-radius: 50px;
      padding-left: $font-size;
      @media screen and (max-width: 1399px) {
        width: 400px;
      }
      &::placeholder {
        color: $color-grey-1;
      }
    }
    button[type="submit"] {
      position: absolute;
      right: 5px;
      top: 5px;
      bottom: 5px;
      background-color: $color-principal-dark;
      border: none;
      color: $color-background;
      border-radius: 5px;
      border-radius: 50px;
      padding: 3px $font-size;
      font-size: $font-size;
      font-weight: $font-weight-bold;
    }
  }
}
