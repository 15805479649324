@charset "UTF-8";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500&display=swap");
:root {
  --app-height: 100%; }

body {
  background-color: #fff;
  font-size: 0.9rem;
  color: #252a32;
  font-weight: 400;
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased; }
  @media screen and (max-width: 575px) {
    body {
      font-size: 0.8rem; } }

a {
  color: #252a32;
  text-decoration: none !important;
  transition: 0.2s all ease-in-out; }
  a:hover {
    color: #6c3e51;
    transition: 0.2s all ease-in-out; }

.error404 .h3-sections.h3-sections--center {
  display: none; }

body.admin-bar.home .header {
  top: 32px; }

body.admin-bar:not(.home) .header {
  top: 32px; }

.pad-b-min {
  padding-bottom: 3rem; }

.mar-b-min {
  margin-bottom: 3rem; }

.pad-b {
  padding-bottom: 6rem; }

.mar-b {
  margin-bottom: 6rem; }

.pad-b-max {
  padding-bottom: 10rem; }

.mar-b-max {
  margin-bottom: 10rem; }

.pad-t-min {
  padding-top: 3rem; }

.mar-t-min {
  margin-top: 3rem; }

.pad-t {
  padding-top: 6rem; }

.mar-t {
  margin-top: 6rem; }

.pad-t-max {
  padding-top: 10rem; }

.mar-t-max {
  margin-top: 10rem; }

.pad-min {
  padding: 3rem 0; }

.pad {
  padding: 6rem 0; }

.pad-max {
  padding: 10rem 0; }

.color-primario {
  background-color: #ff0066; }

.color-secundario {
  background-color: #71a3bf; }

.bg-primario {
  background-color: #ff0066; }

.bg-primario {
  background-color: #71a3bf; }

.bg-gris-light {
  background-color: #f4f4f4; }

.color-alternativo {
  color: #fda11e; }

body:not(.page-template-page-contacto) .grecaptcha-badge {
  visibility: hidden; }

/* SCSS Headers
--------------------------------------------------- */
.h3-header {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem; }
  @media screen and (max-width: 575px) {
    .h3-header {
      font-size: 0.9rem;
      font-weight: 600;
      margin-bottom: 1rem; } }

.h3-sections {
  font-size: 1.5rem;
  margin-bottom: 1.5rem; }
  @media screen and (max-width: 575px) {
    .h3-sections {
      font-size: 1.4rem;
      margin-bottom: 1.5rem;
      font-weight: 600; } }
  .h3-sections--center {
    text-align: center; }
  .h3-sections--underline {
    margin-bottom: 2rem; }
    .h3-sections--underline::after {
      content: "";
      display: block;
      width: 8rem;
      height: 2px;
      border-radius: 50px;
      margin-top: 0.5rem;
      margin-left: auto;
      margin-right: auto;
      background-color: #252a32; }
      @media screen and (max-width: 575px) {
        .h3-sections--underline::after {
          height: 2px;
          width: 6rem;
          background-color: #6c3e51; } }

.header-top {
  text-align: color;
  background-color: #fdd;
  padding: 9px 0;
  font-size: 0.8rem; }
  @media screen and (max-width: 767px) {
    .header-top {
      display: none; } }
  .header-top__item {
    margin: 0 1rem;
    display: inline-block; }
  .header-top__claim {
    font-weight: 600; }
  .header-top__whatsapp a {
    padding-left: 0.9rem; }

.header-nav {
  position: relative;
  z-index: 9;
  background-color: #fff;
  transition: 0.2s all ease-in-out; }
  @media screen and (max-width: 991px) {
    .header-nav {
      display: none !important; } }
  .header-nav--sticky {
    background-color: #fdd;
    transition: 0.2s all ease-in-out; }

.header {
  z-index: 10;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transition: 0.2s all ease-in-out;
  width: 100%;
  position: sticky;
  top: 0; }
  @media screen and (max-width: 1419px) {
    .header {
      padding-left: 1.2rem;
      padding-right: 1.2rem; } }
  @media screen and (max-width: 767px) {
    .header {
      padding-top: 1.2rem;
      padding-bottom: 1.2rem; } }
  @media screen and (max-width: 767px) {
    .header {
      position: sticky;
      top: 0;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem; } }
  @media screen and (max-width: 575px) {
    .header {
      flex-wrap: wrap; } }
  .header__container {
    display: flex;
    justify-content: space-between; }
  .header--home {
    background-color: #fff; }
  .header--sticky {
    background-color: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    transition: 0.2s all ease-in-out;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem; }
    @media screen and (max-width: 767px) {
      .header--sticky {
        padding-top: 0.3rem;
        padding-bottom: 0.3rem; } }
  .header__left {
    display: flex;
    align-items: center; }
    @media screen and (max-width: 575px) {
      .header__left {
        flex: 0 0 50%; } }
  .header__right {
    flex: 1 1 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    @media screen and (max-width: 575px) {
      .header__right {
        flex: 0 0 40%; } }
    .header__right__item {
      margin-left: 1rem; }
      .header__right__item--shipping img {
        width: 110px;
        height: auto; }
        @media screen and (max-width: 1199px) {
          .header__right__item--shipping img {
            width: 100px; } }
      @media screen and (max-width: 991px) {
        .header__right__item--shipping {
          display: none; } }
      @media screen and (max-width: 991px) {
        .header__right__item--search {
          display: none; } }
      @media screen and (min-width: 768px) {
        .header__right__item--searchm {
          display: none; } }
    .header__right .wpml-ls-sidebars-wpml-top {
      margin-left: 2rem !important; }
  .header__shipping {
    flex: 1 1 100%;
    font-size: 0.75rem;
    text-align: right; }
    .header__shipping span {
      background-color: #fda11e;
      color: #fff;
      padding: 1px 8px;
      font-weight: 500;
      border-radius: 50px; }
    @media screen and (min-width: 576px) {
      .header__shipping {
        display: none; } }
  .header__burger {
    width: 50px;
    display: flex;
    align-items: center; }
    @media screen and (max-width: 480px) {
      .header__burger {
        with: 40px; } }
  .header__logo {
    display: inline-flex; }
    .header__logo img {
      width: 170px;
      height: auto; }
      @media screen and (max-width: 767px) {
        .header__logo img {
          width: 140px; } }
      @media screen and (max-width: 480px) {
        .header__logo img {
          width: 120px; } }
      @media screen and (max-width: 360px) {
        .header__logo img {
          width: 100px; } }
  @media screen and (max-width: 991px) {
    .header__promo {
      display: none !important; } }
  .header .bi {
    font-size: 1.8rem; }
    @media screen and (max-width: 575px) {
      .header .bi {
        font-size: 1.5rem; } }
  .header .ico-svg {
    width: 28px !important;
    height: 28px !important; }
  .header__search {
    margin-top: 4px; }
    .header__search .woocommerce-product-search {
      position: relative; }
      .header__search .woocommerce-product-search input[type="search"] {
        width: 100%; }
      .header__search .woocommerce-product-search button[type="submit"] {
        position: absolute;
        inset: 5px;
        left: auto; }
    @media screen and (min-width: 992px) {
      .header__search {
        display: none; } }

.wpml-ls-sidebars-wpml-top.wpml-ls-legacy-list-horizontal {
  border: 0;
  padding: 0;
  margin: 0 !important; }
  .wpml-ls-sidebars-wpml-top.wpml-ls-legacy-list-horizontal li.wpml-ls-current-language a {
    color: #ff0066 !important;
    padding: 0px 10px 0px;
    font-weight: 600; }
  .wpml-ls-sidebars-wpml-top.wpml-ls-legacy-list-horizontal a.wpml-ls-link {
    margin-right: 0px;
    padding-right: 0px;
    line-height: initial; }

.overlay-search {
  position: fixed;
  z-index: 500;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: #252a32;
  display: none; }
  @media screen and (min-width: 768px) {
    .overlay-search {
      display: none; } }
  .overlay-search__container {
    padding: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .overlay-search__container h2 {
      color: #fff;
      margin-top: 0; }
  .overlay-search__logo {
    width: 120px;
    margin-bottom: 2rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto; }
  .overlay-search__link {
    margin-top: 2rem;
    text-align: center; }
    @media screen and (max-width: 575px) {
      .overlay-search__link {
        margin-bottom: 6rem; } }
    .overlay-search__link span {
      color: #fff;
      font-size: 0.8rem;
      display: inline-flex;
      align-items: center;
      justify-content: center; }
      .overlay-search__link span .bi {
        margin-right: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center; }
  .overlay-search .product-search-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; }
  .overlay-search .product-search-field {
    flex: 0 0 100%;
    margin-bottom: 1rem;
    width: 100%;
    text-align: center;
    font-size: 1rem; }
    .overlay-search .product-search-field:focus {
      outline: none; }
  .overlay-search .product-search button[type="submit"] {
    cursor: pointer; }
    .overlay-search .product-search button[type="submit"] .bi {
      margin-right: 5px; }

.woocommerce-demo-store p.demo_store {
  position: fixed;
  height: auto;
  line-height: 19px; }
  @media screen and (max-width: 767px) {
    .woocommerce-demo-store p.demo_store {
      padding-left: 1rem;
      padding-right: 1rem; } }

@media screen and (max-width: 767px) {
  .woocommerce-demo-store header.h {
    top: 62px; } }

.woocommerce-store-notice,
p.demo_store {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  width: 100%;
  font-size: 0.9rem;
  padding: 1em 0;
  text-align: center;
  background-color: #252a32;
  background: #ff0066;
  color: #fff;
  z-index: 99998;
  box-shadow: 0 1px 1em rgba(0, 0, 0, 0.2);
  display: none; }
  @media screen and (max-width: 767px) {
    .woocommerce-store-notice,
    p.demo_store {
      font-size: 0.8rem; } }
  .woocommerce-store-notice a,
  p.demo_store a {
    color: #fff;
    text-decoration: underline; }

.woocommerce-store-notice__dismiss-link {
  font-weight: 600;
  margin-left: 10px; }

.nav-promo-resp {
  padding: 0.9rem; }
  @media screen and (min-width: 768px) {
    .nav-promo-resp {
      display: none; } }
  .nav-promo-resp__items {
    margin: 0;
    padding: 0;
    display: flex;
    gap: 0.9rem;
    justify-content: center;
    align-items: center;
    list-style: none;
    width: 100%; }
  .nav-promo-resp .menu-item {
    flex: 1; }
    .nav-promo-resp .menu-item a {
      font-weight: 600;
      background-color: #f4f4f4;
      display: block;
      text-align: center;
      width: 100%;
      padding: 0.35rem;
      border: 1px solid #707070;
      border-radius: 0.45rem; }

.footer {
  padding: 4rem 0;
  background-color: rgba(0, 0, 0, 0.05);
  background-color: #fdd; }
  @media screen and (max-width: 767px) {
    .footer {
      padding: 2rem 0; } }
  .footer__prefooter {
    margin-bottom: 4rem;
    display: flex; }
    @media screen and (max-width: 991px) {
      .footer__prefooter {
        flex-wrap: wrap; } }
    @media screen and (max-width: 575px) {
      .footer__prefooter {
        margin-bottom: 2rem; } }
  .footer__logo {
    margin-right: 2rem; }
    @media screen and (max-width: 767px) {
      .footer__logo {
        display: flex;
        align-items: center; } }
    .footer__logo img {
      width: 200px;
      height: auto; }
      @media screen and (max-width: 767px) {
        .footer__logo img {
          height: 34px;
          width: auto; } }
  .footer__social {
    display: flex;
    align-items: center;
    margin-right: 4rem; }
    @media screen and (max-width: 767px) {
      .footer__social {
        margin-right: 0; } }
  .footer__financiacion {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.9rem; }
    @media screen and (max-width: 991px) {
      .footer__financiacion {
        margin-top: 2rem; } }
    .footer__financiacion img {
      max-width: 500px !important;
      height: auto; }
      @media screen and (max-width: 767px) {
        .footer__financiacion img {
          width: 100% !important; } }
  .footer__container {
    margin-bottom: 4rem; }
    @media screen and (max-width: 767px) {
      .footer__container {
        margin-bottom: 2rem; } }
  .footer__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    width: 100%; }
    @media screen and (max-width: 767px) {
      .footer__grid {
        grid-template-columns: 1fr 1fr;
        gap: 1rem; } }
  .footer__1 {
    margin-bottom: 1rem; }
    @media screen and (max-width: 767px) {
      .footer__1 {
        grid-column: 1 / 3; } }
    .footer__1 img {
      width: 200px;
      height: auto; }
  @media screen and (max-width: 767px) {
    .footer__4 {
      grid-column: 1 / 3; } }
  .footer__copy {
    text-align: center; }
    @media screen and (max-width: 767px) {
      .footer__copy p {
        margin-bottom: 5px; } }

.compres {
  padding: 2rem 0;
  background-color: #f4f4f4;
  margin-bottom: 2px; }
  @media screen and (max-width: 575px) {
    .compres {
      padding: 1rem 0; } }
  .compres__content {
    display: flex;
    justify-content: space-around; }
    @media screen and (max-width: 575px) {
      .compres__content {
        flex-direction: column;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem 1.5rem; } }
  .compres__item {
    display: flex;
    align-items: center; }
    @media screen and (max-width: 575px) {
      .compres__item {
        align-items: flex-center; } }
    .compres__item:last-child {
      margin-bottom: 0; }
  .compres__icono {
    margin-right: 1.2rem; }
    @media screen and (max-width: 575px) {
      .compres__icono {
        margin-right: 0.8rem;
        flex: 0 0 10%;
        display: flex;
        align-items: flex-start;
        justify-content: center; } }
    .compres__icono .bi {
      font-size: 2rem; }
  .compres__titulo {
    font-weight: 500;
    font-size: 0.8rem; }
    @media screen and (max-width: 575px) {
      .compres__titulo {
        font-weight: 600;
        line-height: 1.2;
        font-size: 0.7rem; } }
    .compres__titulo span {
      display: block;
      color: #707070;
      font-weight: normal; }
      @media screen and (max-width: 575px) {
        .compres__titulo span {
          margin-top: 5px; } }

.ubermenu-main {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05) !important; }
  .ubermenu-main .ubermenu-nav > .ubermenu-item > .ubermenu-target:hover {
    background-color: #fdd; }
  .ubermenu-main .ubermenu-nav > .ubermenu-item > .ubermenu-target > .ubermenu-target-title {
    font-family: "Oswald", sans-serif;
    font-size: 1rem;
    font-weight: 500; }
  .ubermenu-main .ubermenu-submenu .ubermenu-item .ubermenu-target:hover {
    background-color: #fdd; }

.nav-promo {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  margin-left: 2rem; }
  @media screen and (max-width: 1199px) {
    .nav-promo {
      margin-left: 1rem;
      flex-wrap: wrap; } }
  .nav-promo .menu-item a {
    padding: 5px 10px;
    font-family: "Oswald", sans-serif;
    font-size: 1rem;
    font-weight: medium; }
    @media screen and (max-width: 1199px) {
      .nav-promo .menu-item a {
        font-size: 0.9rem; } }

.shiftnav-toggle {
  background: initial !important;
  padding: initial !important;
  color: #252a32 !important;
  box-shadow: none !important;
  font-size: 1.5rem; }

.shiftnav .shiftnav-menu-image img {
  max-width: 50% !important;
  height: auto; }

.header-cat {
  margin-bottom: 3rem; }
  @media screen and (max-width: 767px) {
    .header-cat {
      margin-bottom: 1.5rem; } }
  @media screen and (max-width: 575px) {
    .header-cat .container {
      padding-right: 0;
      padding-left: 0; } }
  .header-cat__container {
    display: flex;
    overflow-x: auto;
    overflow-scrolling: touch; }
    @media screen and (max-width: 767px) {
      .header-cat__container {
        overflow-x: scroll; } }
  .header-cat__item {
    text-align: center;
    width: 120px;
    margin-right: 2rem; }
    @media screen and (max-width: 767px) {
      .header-cat__item:first-child {
        margin-left: 1rem; } }
    @media screen and (max-width: 767px) {
      .header-cat__item:last-child {
        padding-right: 1rem; } }
  .header-cat__figure {
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: inline-block;
    border-radius: 1rem;
    overflow: hidden;
    margin-bottom: 0.5rem; }
  .header-cat__img {
    width: 90px;
    height: 90px;
    object-fit: cover; }
    @media screen and (max-width: 575px) {
      .header-cat__img {
        width: 80px;
        height: 80px; } }
  .header-cat__title {
    font-size: 0.8rem;
    font-weight: 500; }

.widget__title {
  margin-bottom: 1.3rem;
  font-size: 1.2rem;
  font-weight: 600; }
  @media screen and (max-width: 575px) {
    .widget__title {
      margin-bottom: 0.6rem;
      font-size: 1rem; } }

.widget ul {
  list-style: none;
  padding-left: 0rem; }

.widget--footer {
  margin-bottom: 2rem; }
  @media screen and (max-width: 575px) {
    .widget--footer {
      margin-bottom: 0; } }
  .widget--footer .product-categories,
  .widget--footer .menu {
    list-style: none;
    padding-left: 0rem;
    margin-bottom: 0; }
    .widget--footer .product-categories li,
    .widget--footer .menu li {
      padding: 4px 0; }
      .widget--footer .product-categories li a,
      .widget--footer .menu li a {
        font-size: 0.9rem; }
        @media screen and (max-width: 767px) {
          .widget--footer .product-categories li a,
          .widget--footer .menu li a {
            font-size: 0.8rem; } }

.widget--legal {
  margin-bottom: 3rem; }
  @media screen and (max-width: 575px) {
    .widget--legal {
      margin-bottom: 1.5rem; } }
  @media screen and (max-width: 767px) {
    .widget--legal ul.menu {
      display: flex;
      flex-wrap: wrap; } }
  .widget--legal ul.menu .menu-item {
    margin-bottom: 5px;
    font-size: 0.9rem; }
    @media screen and (max-width: 767px) {
      .widget--legal ul.menu .menu-item {
        margin: 0 5px 5px 0; } }
    .widget--legal ul.menu .menu-item a {
      display: inline-block; }
      @media screen and (max-width: 767px) {
        .widget--legal ul.menu .menu-item a {
          padding: 3px 8px;
          background-color: #fdd;
          color: #4d4d4d;
          border-radius: 3px; } }
  .widget--legal ul.menu .current-menu-item a {
    background-color: #4d4d4d;
    color: #f4f4f4;
    font-weight: 600; }
    @media screen and (max-width: 767px) {
      .widget--legal ul.menu .current-menu-item a {
        background-color: #4d4d4d;
        color: #f4f4f4;
        font-weight: normal; } }

@media screen and (max-width: 575px) {
  .widget--page {
    margin-bottom: 2rem !important; } }

.widget--page .product-categories,
.widget--page .menu {
  list-style: none;
  padding-left: 0rem; }
  @media screen and (max-width: 767px) {
    .widget--page .product-categories,
    .widget--page .menu {
      display: flex;
      flex-wrap: wrap; } }
  @media screen and (max-width: 767px) {
    .widget--page .product-categories li,
    .widget--page .menu li {
      margin-right: 8px;
      margin-bottom: 8px; } }
  @media screen and (max-width: 767px) {
    .widget--page .product-categories li a,
    .widget--page .menu li a {
      padding: 4px 8px;
      background-color: #707070;
      color: #fff;
      display: inline-flex;
      border-radius: 2px; } }
  .widget--page .product-categories li.current-menu-item a,
  .widget--page .menu li.current-menu-item a {
    background-color: #252a32; }

.zoom-social-icons-list .zoom-social_icons-list-span {
  background-color: #252a32 !important; }

.widgettitle {
  margin-bottom: 1rem;
  font-size: 1.2rem; }
  @media screen and (max-width: 575px) {
    .widgettitle {
      margin-bottom: 0.3rem;
      font-size: 1rem; } }

@media screen and (max-width: 575px) {
  .zoom-social-icons-list {
    display: flex;
    justify-content: center;
    align-items: center; } }

.widget--page {
  margin-bottom: 4rem; }

.widget_recent_entries,
.widget_categories {
  margin-bottom: 2.5rem; }
  @media screen and (max-width: 575px) {
    .widget_recent_entries,
    .widget_categories {
      margin-bottom: 1.7rem; } }
  .widget_recent_entries h4,
  .widget_categories h4 {
    margin-bottom: 0.5rem; }
  .widget_recent_entries ul,
  .widget_categories ul {
    padding-left: 0px;
    list-style: none; }
  .widget_recent_entries li,
  .widget_categories li {
    margin-bottom: 0.5rem; }

.wpml-ls-sidebars-wpml-top {
  margin-left: 0;
  margin-right: 1rem; }
  .wpml-ls-sidebars-wpml-top .wpml-ls-slot-wpml-top {
    border-radius: 4px; }
    .wpml-ls-sidebars-wpml-top .wpml-ls-slot-wpml-top .wpml-ls-link {
      padding: 0 !important;
      margin: 0px !important;
      margin-left: 10px !important; }
      .wpml-ls-sidebars-wpml-top .wpml-ls-slot-wpml-top .wpml-ls-link .wpml-ls-flag {
        padding: 2px !important;
        border: 3px solid rgba(0, 0, 0, 0.1);
        box-sizing: content-box;
        border-radius: 4px;
        transition: 0.2s all ease-in-out; }
        .wpml-ls-sidebars-wpml-top .wpml-ls-slot-wpml-top .wpml-ls-link .wpml-ls-flag:hover {
          border-color: rgba(0, 0, 0, 0.3) !important;
          transition: 0.2s all ease-in-out; }
  .wpml-ls-sidebars-wpml-top .wpml-ls-current-language .wpml-ls-flag {
    border-color: rgba(0, 0, 0, 0.3) !important;
    transition: 0.2s all ease-in-out; }

.page-title {
  padding-top: 3rem;
  padding-top: 2rem;
  overflow-y: auto; }
  @media screen and (max-width: 575px) {
    .page-title {
      padding-top: 0.5rem; } }
  .page-title__h {
    margin-bottom: 2rem;
    font-size: 2.5rem;
    font-weight: 600; }
    @media screen and (max-width: 575px) {
      .page-title__h {
        margin-bottom: 1.5rem;
        font-size: 1.5rem; } }
    @media screen and (max-width: 360px) {
      .page-title__h {
        margin-bottom: 0.5rem;
        font-size: 1.8rem; } }
  .page-title__breadcrumbs {
    margin-bottom: 0.45rem;
    font-size: 0.8rem;
    color: #707070; }
    @media screen and (max-width: 575px) {
      .page-title__breadcrumbs {
        font-size: 0.6rem;
        margin-bottom: 5px; } }
    .page-title__breadcrumbs a {
      color: #707070; }
    .page-title__breadcrumbs strong {
      font-weight: 400;
      color: #71a3bf; }

.single-product .page-title {
  padding-top: 2rem; }
  @media screen and (max-width: 575px) {
    .single-product .page-title {
      padding-top: 0.5rem; } }

.page-id-9 .page-title {
  display: none; }

.h-estilos {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem; }
  .h-estilos__cover {
    flex: 1 0 50%; }
    .h-estilos__cover img {
      width: 100%;
      height: auto;
      border-radius: 0.45rem; }
  .h-estilos__desc {
    flex: 1 0 40%; }

/* SCSS Botones
--------------------------------------------------- */
.link-underline {
  text-decoration: underline; }

.boton {
  font-size: 0.9rem;
  background-color: #252a32;
  color: #fff;
  font-weight: 500;
  padding: 0.85rem 1.5rem;
  border: 2px;
  border-radius: 0px;
  letter-spacing: 1px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  display: inline-flex;
  align-items: center; }
  @media (max-width: 575px) {
    .boton {
      padding: 0.75rem 1.2rem; } }
  @media (max-width: 390px) {
    .boton {
      padding: 0.7rem 1rem;
      font-size: 0.8rem; } }
  .boton .bi {
    margin-left: 5px; }
  .boton:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    transition: 0.2s all ease-in-out;
    background-color: #252a32; }
  .boton .bi {
    display: inline-flex;
    align-items: center; }

.boton--whatsapp .bi,
.boton--telegram .bi {
  margin-right: 10px; }

.boton--whatsapp {
  background-color: #075e54 !important;
  color: #25d366; }
  .boton--whatsapp:hover {
    background-color: #075e54 !important;
    color: #25d366; }

.boton--blanco {
  background-color: #fff !important;
  color: #252a32 !important; }
  .boton--blanco:hover {
    background-color: #fff !important;
    color: #252a32; }

.boton--principal {
  background-color: #ff0066 !important;
  color: #fff !important; }
  .boton--principal:hover {
    background-color: #ff0066 !important;
    color: #fff; }

.boton--outline-blanco {
  padding: 0.85rem 1.5rem;
  border: 2px;
  border-radius: 0px;
  letter-spacing: 1px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  display: inline-flex;
  align-items: center;
  background-color: initial !important;
  color: #fff;
  border-color: #fff;
  border-style: solid; }
  @media (max-width: 575px) {
    .boton--outline-blanco {
      padding: 0.75rem 1.2rem; } }
  @media (max-width: 390px) {
    .boton--outline-blanco {
      padding: 0.7rem 1rem;
      font-size: 0.8rem; } }
  .boton--outline-blanco .bi {
    margin-left: 5px; }
  .boton--outline-blanco:hover {
    background-color: #fff !important;
    color: #252a32; }

.boton--lg {
  padding: 1.3rem 1.5rem;
  border: 2px;
  letter-spacing: 1px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: 0.2s all ease-in-out; }

.boton--sm {
  padding: 0.55rem 1.2rem;
  letter-spacing: 0px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: 0.2s all ease-in-out; }

.boton--block {
  text-align: center;
  display: block;
  width: 100%; }

.blog-listado {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem; }
  @media screen and (max-width: 575px) {
    .blog-listado {
      grid-template-columns: 1fr;
      gap: 2.5rem; } }
  .blog-listado__thumbnail {
    margin-bottom: 0.5rem; }
    @media screen and (max-width: 575px) {
      .blog-listado__thumbnail {
        margin-bottom: 0.5rem; } }
    .blog-listado__thumbnail img {
      width: 100%;
      height: auto;
      margin-bottom: 1.2rem; }
      @media screen and (max-width: 575px) {
        .blog-listado__thumbnail img {
          margin-bottom: 0.8rem; } }
  .blog-listado__categoria {
    margin-bottom: 0.2rem; }
  @media screen and (max-width: 575px) {
    .blog-listado__titulo {
      font-size: 1rem; } }
  .blog-listado__titulo a {
    font-weight: 600;
    font-size: 1.3rem;
    display: inline-block;
    line-height: 1.3; }

.page__entry a {
  color: #ff0066;
  text-decoration: underline; }

.page__entry h2,
.page__entry h3,
.page__entry p,
.page__entry ul,
.page__entry ol,
.page__entry .wp-block-image,
.page__entry table {
  margin-bottom: 1.7rem; }
  @media screen and (max-width: 575px) {
    .page__entry h2,
    .page__entry h3,
    .page__entry p,
    .page__entry ul,
    .page__entry ol,
    .page__entry .wp-block-image,
    .page__entry table {
      margin-bottom: 1.2rem; } }

.page__entry ul li {
  margin-bottom: 5px; }

.page__entry h2 {
  font-weight: 700; }
  .page__entry h2 strong {
    font-weight: 700; }

.page__entry h3 {
  color: #4d4d4d;
  font-size: 1.4rem;
  color: #71a3bf;
  font-weight: 600; }

.page__entry .wp-block-image.size-full img {
  max-width: 100% !important;
  height: auto !important;
  border-radius: 0.45rem;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1); }

.post .wp-block-button__link {
  background-color: #ff0066;
  color: #fff;
  text-decoration: none;
  border-radius: 50px;
  font-size: 0.8rem; }

.post .wc-block-grid__product {
  padding: 1rem;
  background-color: #f4f4f4; }
  @media screen and (min-width: 768px) {
    .post .wc-block-grid__product {
      margin: 0.5rem;
      border: 0;
      flex: 0 0 30% !important;
      max-width: 30% !important; } }
  .post .wc-block-grid__product__product-image {
    text-align: center; }
    @media screen and (max-width: 575px) {
      .post .wc-block-grid__product__product-image img {
        width: 80% !important; } }

.post .wc-block-grid__product-price {
  margin-bottom: 0.5rem !important; }

.post .wc-block-grid__product-link {
  text-decoration: none !important; }

.post__title {
  font-size: 3rem;
  font-weight: 600;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .post__title {
      font-size: 1.5rem; } }

.post__meta {
  margin-bottom: 1rem;
  text-align: center; }
  @media screen and (max-width: 575px) {
    .post__meta {
      margin-bottom: 1rem; } }

.post__autor {
  text-align: center;
  margin-bottom: 6rem; }
  @media screen and (max-width: 575px) {
    .post__autor {
      margin-bottom: 3rem; } }

.post__entry {
  margin-bottom: 6rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.9); }
  .post__entry h2 {
    font-size: 2.3rem;
    margin: 2.5rem 0rem 1rem 0;
    color: #ff0066; }
  .post__entry h3 {
    font-size: 1.4rem;
    font-weight: 400;
    margin: 2.5rem 0rem 1rem 0;
    text-decoration: underline; }
  .post__entry h4 {
    font-size: 1.2rem;
    font-style: italic;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.3;
    margin: 2.5rem 0rem 1rem 0; }
  .post__entry figure {
    margin: 2rem 0; }
  .post__entry figcaption {
    font-size: 0.7rem; }
  .post__entry img {
    width: 100%;
    height: auto; }
  .post__entry p,
  .post__entry ul,
  .post__entry ol {
    margin-bottom: 1.8rem;
    line-height: 1.7;
    font-size: 1rem; }
  .post__entry blockquote.wp-block-quote {
    text-align: center !important;
    padding: 1.3rem; }
    @media screen and (max-width: 575px) {
      .post__entry blockquote.wp-block-quote {
        font-size: 1.1rem;
        margin-left: 0rem; } }
    .post__entry blockquote.wp-block-quote p {
      font-size: 1.1rem;
      line-height: 1.6;
      margin-bottom: 1.2rem;
      color: #6c3e51;
      text-align: center; }
    .post__entry blockquote.wp-block-quote cite {
      font-size: 0.8rem;
      font-weight: 400;
      margin-bottom: 0.2 !important;
      font-style: normal;
      font-weight: 600;
      background-color: rgba(0, 0, 0, 0.1);
      padding: 1px 9px;
      border-radius: 3px; }
      @media screen and (max-width: 575px) {
        .post__entry blockquote.wp-block-quote cite {
          padding: 0;
          background-color: initial; } }
  .post__entry .wp-block-pullquote {
    background-color: rgba(244, 244, 228, 0.9) !important;
    border-width: 2px 0;
    border-color: rgba(202, 202, 176, 0.9);
    border-style: solid; }
    .post__entry .wp-block-pullquote blockquote cite {
      font-size: 0.8rem;
      font-weight: 400;
      font-style: normal;
      font-weight: 600; }
  .post__entry a {
    color: #ff0066;
    text-decoration: underline; }

.autor {
  display: inline-flex; }
  .autor__avatar {
    margin-right: 1rem; }
    .autor__avatar img {
      border-radius: 50px;
      width: 60px;
      height: auto; }
  .autor__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start; }
  .autor__name {
    font-weight: 600; }

.blog-lista {
  background-color: #f4f4f4;
  padding: 4rem 0; }
  @media screen and (max-width: 575px) {
    .blog-lista {
      padding: 2rem 0; } }

.blog-listado__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem; }
  @media screen and (max-width: 575px) {
    .blog-listado__grid {
      grid-template-columns: repeat(2, 1fr); } }
  .blog-listado__grid img {
    width: 100%;
    height: auto; }

.blog-listado__titular {
  margin-bottom: 2rem;
  font-size: 2rem; }
  @media screen and (max-width: 575px) {
    .blog-listado__titular {
      margin-bottom: 1rem;
      font-size: 1rem; } }

.blog-listado a {
  display: inline-block; }

.blog-listado__thumb-link {
  margin-bottom: 1rem; }
  @media screen and (max-width: 575px) {
    .blog-listado__thumb-link {
      font-size: 0.7rem; } }

.blog-listado__category {
  font-size: 0.8rem; }

.blog-listado__titulo {
  font-size: 1.1rem; }
  @media screen and (max-width: 575px) {
    .blog-listado__titulo {
      font-size: 0.9rem; } }

@media screen and (max-width: 575px) {
  .blog-listado__lectura {
    font-size: 0.7rem; } }

.wpcf7 {
  margin: 1.5rem 0; }
  .wpcf7 .form-group {
    margin-bottom: 1rem; }
  .wpcf7 .form-control {
    width: 100%; }
  .wpcf7 .wpcf7-not-valid-tip {
    font-size: 12px;
    color: red; }

.wpcf7-acceptance {
  font-size: 0.7rem;
  color: #707070;
  color: rgba(0, 0, 0, 0.4); }

.wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.5em 1em;
  border-radius: 8px; }

.wpcf7-mail-sent-ok {
  border: 2px solid #398f14;
  background-color: #398f14;
  color: #fff; }

.wpcf7-mail-sent-ng {
  border: 2px solid #ff0000;
  background-color: #ff0000;
  color: #fff; }

.wpcf7-spam-blocked {
  border: 2px solid #ffa500;
  background-color: #ffa500; }

.wpcf7-acceptance-missig {
  border: 2px solid #ffa500 !important;
  background-color: #ffa500 !important; }

.woocommerce-product-search {
  position: relative; }
  .woocommerce-product-search .search-field {
    background-color: #f4f4f4;
    border: none;
    font-size: 0.85rem;
    padding-right: 3rem;
    width: 300px; }
  .woocommerce-product-search button[type="submit"] {
    margin-left: -5rem;
    background-color: #707070;
    border: none;
    color: #fff;
    border-radius: 5px;
    padding: 3px 10px; }

.header__right .woocommerce-product-search {
  position: relative; }
  .header__right .woocommerce-product-search .search-field {
    background-color: #f4f4f4;
    background: #eaf4f2;
    color: #262626;
    border: none;
    border: 1px solid #d2dad8;
    font-size: 0.9rem;
    padding-right: 3rem;
    width: 500px;
    border-radius: 50px;
    padding-left: 0.9rem; }
    @media screen and (max-width: 1399px) {
      .header__right .woocommerce-product-search .search-field {
        width: 400px; } }
    .header__right .woocommerce-product-search .search-field::placeholder {
      color: #262626; }
  .header__right .woocommerce-product-search button[type="submit"] {
    position: absolute;
    right: 5px;
    top: 5px;
    bottom: 5px;
    background-color: #6c3e51;
    border: none;
    color: #fff;
    border-radius: 5px;
    border-radius: 50px;
    padding: 3px 0.9rem;
    font-size: 0.9rem;
    font-weight: 600; }

#cookie-law-info-bar {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }
  @media screen and (max-width: 767px) {
    #cookie-law-info-bar {
      padding-top: 1.2rem !important;
      padding-bottom: 1.2rem !important; } }
  #cookie-law-info-bar #cookie_action_close_header {
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 4px; }
    @media screen and (max-width: 767px) {
      #cookie-law-info-bar #cookie_action_close_header {
        padding: 10px 1.2rem 11px !important; } }
  #cookie-law-info-bar .cli-plugin-main-link {
    font-size: 0.8rem; }
  #cookie-law-info-bar .cookie-actions {
    margin-top: 0.5rem;
    display: inline-block; }

.hero {
  background-color: #ebeae8;
  position: relative; }
  @media screen and (max-width: 575px) {
    .hero {
      display: none; } }
  .hero__overlay {
    top: 0;
    bottom: 0;
    width: 100%;
    position: absolute;
    z-index: 1;
    background: rgba(0, 0, 0, 0.4);
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%); }
    @media screen and (max-width: 767px) {
      .hero__overlay {
        background-color: rgba(0, 0, 0, 0.4);
        top: 0;
        bottom: 0;
        width: 100%;
        position: absolute;
        z-index: 0; } }
  .hero__img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0; }
  .hero__bg {
    height: 600px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    @media screen and (max-width: 767px) {
      .hero__bg {
        height: 400px; } }
    @media screen and (max-width: 575px) {
      .hero__bg {
        height: 250px;
        align-items: flex-end;
        padding-bottom: 1.5rem; } }
  .hero__content {
    color: #fff;
    width: 50%;
    position: relative;
    z-index: 1; }
    @media screen and (max-width: 991px) {
      .hero__content {
        width: 80%; } }
    @media screen and (max-width: 767px) {
      .hero__content {
        width: 100%;
        text-align: center; } }
  .hero__pretitulo {
    font-size: 1.2rem;
    font-weight: 600; }
    @media screen and (max-width: 767px) {
      .hero__pretitulo {
        font-size: 0.9rem;
        text-transform: uppercase; } }
  .hero__titulo {
    font-weight: 600;
    font-size: 3rem; }
    @media screen and (max-width: 767px) {
      .hero__titulo {
        font-size: 2rem; } }
  .hero__link {
    display: inline-block;
    margin-bottom: 3rem; }

.home .g-estilos {
  margin-bottom: 3rem; }

.g-estilos {
  margin-top: 1px; }
  .g-estilos__items {
    display: flex !important;
    gap: 1px; }
    @media screen and (max-width: 1199px) {
      .g-estilos__items {
        display: grid !important;
        gap: 1px;
        grid-template-columns: 1fr 1fr 1fr; } }
    @media screen and (max-width: 991px) {
      .g-estilos__items {
        grid-template-columns: 1fr 1fr; } }
    @media screen and (max-width: 767px) {
      .g-estilos__items {
        grid-template-columns: 1fr; } }
  .g-estilos__item {
    flex: 1;
    display: flex !important;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;
    overflow: hidden;
    aspect-ratio: 4 / 5;
    overflow: hidden; }
    @media screen and (max-width: 991px) {
      .g-estilos__item {
        aspect-ratio: 16 / 9; } }
    @media screen and (max-width: 767px) {
      .g-estilos__item {
        aspect-ratio: 16 / 8; } }
  .g-estilos__titulo {
    font-size: 1.2rem;
    position: relative;
    z-index: 2;
    text-align: center; }
    @media screen and (max-width: 575px) {
      .g-estilos__titulo {
        font-size: 1.4rem;
        font-weight: 600;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.5); } }
  .g-estilos__content {
    position: relative;
    z-index: 1;
    padding: 0.9rem;
    color: #fff !important;
    width: 100%; }
    .g-estilos__content::before {
      content: "";
      background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      height: 6rem;
      position: absolute;
      z-index: 1; }
  .g-estilos__figure {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 0; }
    .g-estilos__figure img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.destacados {
  padding: 2rem 0 3rem 0; }
  @media screen and (max-width: 575px) {
    .destacados {
      padding: 3rem 0 3rem 0; } }
  .destacados__container {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto; }
    @media screen and (max-width: 767px) {
      .destacados__container {
        max-width: 100%;
        width: 100%;
        margin: 0; } }
  @media screen and (max-width: 575px) {
    .destacados ul.products {
      margin-top: 0rem; } }

.home ul.products.columns-3 {
  display: block !important; }
  .home ul.products.columns-3 li.product {
    margin: 0 1rem; }
    @media screen and (max-width: 575px) {
      .home ul.products.columns-3 li.product {
        margin: 0 0.5rem; } }

.slick-slider {
  cursor: url("../assets/ico/cursor-scroll.png"), auto; }
  .slick-slider a {
    cursor: url("../assets/ico/cursor-scroll.png"), auto; }
  .slick-slider .slick-track {
    padding-top: 1rem; }
  .slick-slider .slick-prev,
  .slick-slider .slick-next {
    width: 3rem;
    height: 3rem;
    top: 40%;
    z-index: 2; }
    .slick-slider .slick-prev::before,
    .slick-slider .slick-next::before {
      color: #4d4d4d;
      font-size: 3rem !important;
      background-color: #fff;
      border-radius: 50px; }

.cat-home__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 3rem; }
  @media screen and (max-width: 575px) {
    .cat-home__container {
      grid-template-columns: 1fr;
      gap: 1px; } }

.cat-home__item {
  height: 700px;
  background-color: #f4f4f4;
  position: relative;
  display: block; }
  @media screen and (max-width: 1200px) {
    .cat-home__item {
      height: 500px; } }
  @media screen and (max-width: 991px) {
    .cat-home__item {
      height: 350px; } }
  @media screen and (max-width: 575px) {
    .cat-home__item {
      height: 220px; } }
  .cat-home__item::after {
    content: "";
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    height: 6rem;
    position: absolute;
    z-index: 2; }

.cat-home__background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  z-index: 1;
  position: relative; }

.cat-home__description {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: 3;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600; }
  @media screen and (max-width: 575px) {
    .cat-home__description {
      padding: 1rem;
      align-items: center;
      justify-content: flex-end; } }
  .cat-home__description h2 {
    font-size: 1.9rem; }
    @media screen and (max-width: 575px) {
      .cat-home__description h2 {
        font-size: 2.1rem;
        font-weight: 600;
        margin-bottom: 0; } }
  .cat-home__description span {
    font-size: 0.9rem; }
    @media screen and (max-width: 575px) {
      .cat-home__description span {
        font-size: 0.8rem; } }

.index-press {
  padding: 4rem 0;
  text-align: center;
  background-color: #fff1dd;
  margin-bottom: 2px; }
  .index-press__container {
    width: 75%;
    margin-left: auto;
    margin-right: auto; }
    @media screen and (max-width: 991px) {
      .index-press__container {
        width: 90%; } }
    @media screen and (max-width: 767px) {
      .index-press__container {
        width: 100; } }
  .index-press__empresa {
    text-transform: uppercase;
    margin-bottom: 1rem;
    display: inline-block;
    color: 0.8rem; }
  .index-press__h1 {
    font-size: 1.9rem;
    font-weight: 600; }
    @media screen and (max-width: 767px) {
      .index-press__h1 {
        font-size: 1.5rem; } }
  .index-press__descripcion {
    margin-bottom: 3rem; }
    .index-press__descripcion:last-child {
      margin-bottom: 0; }
  .index-press__h2 {
    font-size: 1.3rem;
    font-weight: 600; }
    @media screen and (max-width: 575px) {
      .index-press__h2 {
        font-size: 1.1rem; } }

.marcas__content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }

.marcas__item {
  padding: 5px 10px; }

.g-contacto {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem; }
  @media screen and (max-width: 767px) {
    .g-contacto {
      grid-template-columns: 1fr; } }

.contactos {
  list-style: none;
  padding-left: 3rem;
  margin-left: 3rem;
  border-left: 1px solid #707070; }
  @media (min-width: 768px) and (max-width: 991px) {
    .contactos {
      margin-left: 1rem;
      padding-left: 1rem; } }
  @media (max-width: 575px) {
    .contactos {
      padding-left: 0;
      margin-left: 0;
      border: 0; } }
  .contactos li {
    margin-bottom: 2rem; }
  .contactos li strong {
    text-transform: uppercase;
    display: block;
    color: #252a32;
    margin-bottom: 4px; }

.map iframe {
  width: 100%;
  height: 600px; }
  @media screen and (max-width: 767px) {
    .map iframe {
      height: 400px; } }

.nosaltres {
  padding: 1rem 0 2rem 0; }
  .nosaltres__content {
    display: grid;
    grid-template-columns: 4fr 8fr;
    gap: 5rem; }
  .nosaltres__img {
    margin-bottom: 2rem; }
    .nosaltres__img img {
      width: 100%;
      height: auto; }

.lookbook {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 2rem;
  margin-bottom: 100px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto; }
  .lookbook__item {
    display: flex;
    align-items: center; }
  .lookbook__img {
    width: 100%;
    height: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.03); }

@media screen and (max-width: 767px) {
  .page-legal {
    padding-top: 0; } }

#submit_bizum_payment_form,
#submit_redsys_payment_form {
  font-size: 0.9rem;
  background-color: #252a32;
  color: #fff;
  margin-right: 1rem;
  box-shadow: none;
  width: auto !important;
  padding: 0.85rem 1.5rem;
  border: 2px;
  border-radius: 0px;
  letter-spacing: 1px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  display: inline-flex;
  align-items: center;
  display: inline-flex !important; }
  @media (max-width: 575px) {
    #submit_bizum_payment_form,
    #submit_redsys_payment_form {
      padding: 0.75rem 1.2rem; } }
  @media (max-width: 390px) {
    #submit_bizum_payment_form,
    #submit_redsys_payment_form {
      padding: 0.7rem 1rem;
      font-size: 0.8rem; } }
  #submit_bizum_payment_form .bi,
  #submit_redsys_payment_form .bi {
    margin-left: 5px; }

.button.cancel {
  background-color: #707070;
  color: #4d4d4d; }

.submit,
.button {
  font-size: 0.9rem;
  background-color: #6c3e51;
  color: #fff;
  display: inline;
  box-shadow: none;
  padding: 0.85rem 1.5rem;
  border: 2px;
  border-radius: 0px;
  letter-spacing: 1px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  display: inline-flex;
  align-items: center; }
  @media (max-width: 575px) {
    .submit,
    .button {
      padding: 0.75rem 1.2rem; } }
  @media (max-width: 390px) {
    .submit,
    .button {
      padding: 0.7rem 1rem;
      font-size: 0.8rem; } }
  .submit .bi,
  .button .bi {
    margin-left: 5px; }
  .submit:hover,
  .button:hover {
    background-color: #6c3e51;
    color: #fff; }

.single_add_to_cart_button {
  padding: 1.1rem 2rem 1rem !important;
  display: flex;
  align-items: center;
  height: auto;
  font-size: 1.1rem !important;
  letter-spacing: 0; }
  @media (max-width: 575px) {
    .single_add_to_cart_button {
      font-size: 0.9rem !important;
      padding: 0.8rem 1rem !important; } }
  @media (max-width: 575px) {
    .single_add_to_cart_button::before {
      font-size: 1rem;
      margin-right: 0.6rem; } }
  .single_add_to_cart_button:hover {
    background-color: #3f708b !important; }

form.cart button[type="submit"]:not(#qib_id):not(_):not(_) {
  height: auto !important; }

@media screen and (max-width: 357px) {
  form.cart {
    display: flex;
    flex-direction: column;
    gap: 0.5rem; } }

.single_add_to_cart_button.disabled {
  background-color: #d9dde3;
  cursor: not-allowed; }
  .single_add_to_cart_button.disabled:hover {
    background-color: #d9dde3 !important; }

.checkout-button,
.single_add_to_cart_button {
  background-color: #ff0066;
  color: #fff !important; }
  .checkout-button:hover,
  .single_add_to_cart_button:hover {
    background-color: #6c3e51 !important;
    color: #fff; }

.button[aria-disabled="false"] {
  border: 0; }

.button[aria-disabled="true"] {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.2);
  border: 0;
  cursor: not-allowed; }

.vi-wpvs-variation-wrap-image .vi-wpvs-option-wrap {
  padding: 2px !important;
  border-radius: 0 !important;
  width: 70px !important;
  height: 70px !important; }
  .vi-wpvs-variation-wrap-image .vi-wpvs-option-wrap .vi-wpvs-option-image {
    width: 70px !important;
    height: 70px !important; }

.vi-wpvs-variation-wrap.vi-wpvs-variation-wrap-vi_wpvs_button_design
.vi-wpvs-option-wrap.vi-wpvs-option-wrap-default {
  border: 1px solid #707070 !important;
  border-radius: 3px; }

.page-id-7 .woocommerce {
  display: grid;
  grid-template-columns: 3.3fr 2fr;
  grid-column-gap: 2rem;
  grid-row-gap: 0; }
  @media (max-width: 991px) {
    .page-id-7 .woocommerce {
      display: block; } }
  .page-id-7 .woocommerce .woocommerce-notices-wrapper {
    grid-column: 1 / 3; }

.cart_totals,
.shop_table {
  width: 100%; }

/* Tabla del carrito de la compra CELDAS INDIVIDUALES */
.woocommerce-cart-form {
  margin-bottom: 2rem; }
  .woocommerce-cart-form .shop_table {
    width: 100%;
    box-shadow: none !important; }
  .woocommerce-cart-form .product-remove {
    width: 40px;
    font-size: 1.2rem;
    text-align: center; }
    @media (max-width: 767px) {
      .woocommerce-cart-form .product-remove::before {
        display: none; } }
  .woocommerce-cart-form .product-remove a {
    font-size: 1.5rem;
    color: rgba(129, 13, 13, 0.774) !important; }
  .woocommerce-cart-form .product-thumbnail {
    display: flex;
    align-items: center;
    height: 100%; }
  .woocommerce-cart-form .product-thumbnail a {
    display: flex; }
  .woocommerce-cart-form .product-thumbnail img {
    width: 50px;
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px; }
  .woocommerce-cart-form .product-name {
    width: 50%;
    text-align: left;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1rem !important; }
    .woocommerce-cart-form .product-name .variation {
      display: grid;
      grid-template-columns: 1fr 3fr;
      gap: 5px 10px;
      font-size: 12px;
      margin-top: 1rem;
      margin-bottom: 0px;
      font-family: "Work Sans", sans-serif; }
      @media screen and (max-width: 767px) {
        .woocommerce-cart-form .product-name .variation {
          grid-template-columns: 3fr 1fr; } }
      .woocommerce-cart-form .product-name .variation dt {
        margin-right: 5px;
        font-weight: normal;
        margin-bottom: 3px; }
      .woocommerce-cart-form .product-name .variation dd {
        margin-left: 0;
        margin-right: 5px;
        margin-bottom: 3px; }
        .woocommerce-cart-form .product-name .variation dd p {
          margin: 0;
          display: inline-flex; }
  .woocommerce-cart-form .product-price {
    text-align: right; }
  .woocommerce-cart-form .product-quantity {
    text-align: right; }
    .woocommerce-cart-form .product-quantity .qty {
      padding: 9px 2px; }
  .woocommerce-cart-form .product-subtotal {
    font-weight: 600;
    text-align: right; }
    .woocommerce-cart-form .product-subtotal .tax_label {
      font-size: 11px !important;
      font-weight: normal;
      display: block; }
  .woocommerce-cart-form thead tr th {
    height: 3rem;
    line-height: 3rem;
    font-size: 0.8rem !important;
    text-transform: uppercase; }

/* Tabla del carrito de la compra -------------------------- */
@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table thead {
    display: none; } }

@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table tbody tr {
    display: flex;
    flex-direction: column; } }

@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table tbody td {
    display: block;
    width: 100%;
    text-align: right;
    font-size: 0.9rem; } }

@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table tbody td.product-thumbnail {
    display: none; } }

@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table .cart_item {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 2px;
    margin-bottom: 10px; } }

.woocommerce-cart-form table.shop_table .cart_item td {
  border-bottom: 2px solid #fff;
  background-color: rgba(0, 0, 0, 0.03); }

.woocommerce-cart-form table.shop_table td {
  padding: 20px 9px;
  vertical-align: middle;
  line-height: 1.5em; }
  @media screen and (max-width: 575px) {
    .woocommerce-cart-form table.shop_table td {
      padding: 5px 9px; } }

.woocommerce-cart-form table.shop_table td a {
  color: #6c3e51; }

.woocommerce-cart-form table.shop_table th {
  font-weight: 700;
  padding: 7px 9px;
  line-height: 1.5em; }

.woocommerce-cart-form table.shop_table .actions {
  padding: 1rem 0 0 0; }
  @media (max-width: 575px) {
    .woocommerce-cart-form table.shop_table .actions::before {
      display: none; } }

.coupon {
  width: 100%;
  margin-bottom: 0.5rem; }
  .coupon label {
    display: none; }
  @media (max-width: 767px) {
    .coupon {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1rem; } }
  @media (max-width: 767px) {
    .coupon input {
      margin-right: 10px;
      width: 50%; } }
  .coupon button {
    padding: 12px 15px; }

/* Atributos en tabla para responsive --------------- */
@media (max-width: 767px) {
  .woocommerce table.shop_table_responsive tr td::before,
  .woocommerce-page table.shop_table_responsive tr td::before {
    content: attr(data-title) ": ";
    font-weight: 400;
    float: left; } }

.cart_totals table.shop_table {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 -1px 24px 0;
  text-align: left;
  width: 100%;
  border-collapse: separate;
  border-radius: 5px; }

.woocommerce table.shop_table .screen-reader-text {
  display: none; }

.update_cart {
  float: right; }

.cart-collaterals {
  width: 100%;
  display: flex;
  justify-content: right; }

/* CARRITO TOTALES Y TRANSPORTE ------------------- */
.cart_totals {
  display: block; }
  @media (max-width: 767px) {
    .cart_totals {
      width: 100%;
      font-size: 0.9rem !important; } }
  .cart_totals h2 {
    font-size: 1.5rem !important; }
  .cart_totals .shop_table {
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin-bottom: 2rem;
    border-radius: 3px; }
  .cart_totals tbody tr td,
  .cart_totals tbody tr th {
    padding: 12px 12px !important; }
  .cart_totals tbody tr td {
    border: 1px solid rgba(0, 0, 0, 0.05); }
    .cart_totals tbody tr td::before {
      display: none; }
  .cart_totals tbody tr th {
    font-size: 0.7rem;
    text-align: right;
    width: 25%; }
    @media (max-width: 767px) {
      .cart_totals tbody tr th {
        width: 25%; } }
  .cart_totals tbody tr th:nth-child(1) {
    background-color: rgba(0, 0, 0, 0.05); }
  .cart_totals h2 {
    margin-top: 0; }
  .cart_totals .checkout-button {
    display: block;
    font-size: 1.3rem;
    text-align: center;
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }

.woocommerce-shipping-methods {
  padding-left: 0;
  list-style: none;
  margin-top: 0;
  margin-bottom: 5px !important; }
  .woocommerce-shipping-methods li label {
    font-weight: 600; }
  .woocommerce-shipping-methods li label span {
    font-weight: normal; }
  .woocommerce-shipping-methods li .betrs_option_desc {
    font-size: 0.7rem; }

.woocommerce-cart .cross-sells > h2 {
  margin-top: 2rem;
  font-size: 1.5rem !important;
  color: #252a32 !important; }
  @media screen and (max-width: 767px) {
    .woocommerce-cart .cross-sells > h2 {
      font-size: 1.2rem !important;
      line-height: 1.4 !important; } }

.woocommerce-cart .cross-sells ul.products {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem; }
  @media screen and (max-width: 767px) {
    .woocommerce-cart .cross-sells ul.products {
      grid-template-columns: 1fr 1fr; } }

.woocommerce #content table.cart td.actions,
.woocommerce table.cart td.actions,
.woocommerce-page #content table.cart td.actions,
.woocommerce-page table.cart td.actions {
  text-align: right; }

.woocommerce #content table.cart td.actions .coupon,
.woocommerce table.cart td.actions .coupon,
.woocommerce-page #content table.cart td.actions .coupon,
.woocommerce-page table.cart td.actions .coupon {
  float: left; }

.hook-cart .bi {
  color: #fda11e; }

.woocommerce-form-coupon-toggle {
  margin-bottom: 1rem; }

.col2-set {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem; }
  @media (max-width: 767px) {
    .col2-set {
      grid-template-columns: 1fr;
      grid-gap: 2rem; } }
  .col2-set .col-1 {
    margin-bottom: 2rem; }
  .col2-set .col-2,
  .col2-set .col-1 {
    width: 100%; }

#order_review_heading,
.woocommerce-billing-fields h3,
.woocommerce-billing-fields h3 label span {
  font-size: 1rem !important;
  margin-top: 0;
  color: #6c3e51 !important;
  font-weight: 500;
  margin-bottom: 2rem; }

.woocommerce-address-fields label,
.woocommerce-checkout label {
  display: block;
  margin-bottom: 2px;
  font-weight: 500;
  font-size: 0.9rem; }

.woocommerce-address-fields .woocommerce-input-wrapper,
.woocommerce-checkout .woocommerce-input-wrapper {
  width: 100%; }

.woocommerce-address-fields input,
.woocommerce-address-fields textarea,
.woocommerce-checkout input,
.woocommerce-checkout textarea {
  display: block;
  width: 100%; }

.form-row-wide {
  margin-top: 0 !important; }

@media (min-width: 992px) {
  #billing_phone_field,
  #billing_postcode_field,
  .form-row-first {
    width: 48%;
    display: inline-block;
    margin-right: 4%; } }

@media (min-width: 992px) {
  #billing_email_field,
  #billing_city_field,
  .form-row-last {
    width: 48%;
    display: inline-block; } }

/* checkout ---------------------------------- */
@media (min-width: 992px) {
  form.woocommerce-checkout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    column-gap: 3rem;
    row-gap: 1rem; } }

@media (min-width: 992px) {
  form.woocommerce-checkout #customer_details {
    grid-column: 1 / 2;
    grid-row: 1 / 3; } }

@media (min-width: 992px) {
  form.woocommerce-checkout #order_review_heading {
    grid-column: 2 / 3;
    grid-row: 1 / 2; } }

@media (min-width: 992px) {
  form.woocommerce-checkout #order_review {
    grid-column: 2 / 3;
    grid-row: 2 / 3; } }

@media (min-width: 992px) {
  #customer_details.col2-set {
    display: flex;
    flex-direction: column;
    gap: 0; } }

/* checkout ---------------------------------- */
.woocommerce-form__label-for-checkbox .woocommerce-terms-and-conditions-checkbox-text {
  display: inline;
  font-size: 1.1rem; }

.woocommerce-form__label-for-checkbox .woocommerce-form__input-checkbox {
  width: auto;
  margin-right: 10px;
  margin-top: 5px;
  display: inline; }

.woocommerce-table--order-details,
.woocommerce-checkout-review-order .shop_table {
  background-color: #f4f4f4;
  padding: 1.4rem !important;
  margin-bottom: 3rem; }

.woocommerce-table--order-details thead tr th,
.woocommerce-checkout-review-order-table thead tr th {
  text-align: left;
  padding: 9px 12px;
  font-size: 1.3rem; }
  @media (max-width: 767px) {
    .woocommerce-table--order-details thead tr th,
    .woocommerce-checkout-review-order-table thead tr th {
      padding: 5px 8px; } }

.woocommerce-table--order-details tfoot tr th,
.woocommerce-table--order-details tfoot tr td,
.woocommerce-table--order-details tbody tr td,
.woocommerce-checkout-review-order-table tfoot tr th,
.woocommerce-checkout-review-order-table tfoot tr td,
.woocommerce-checkout-review-order-table tbody tr td {
  padding: 9px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: left;
  font-size: 0.8rem; }
  @media (max-width: 767px) {
    .woocommerce-table--order-details tfoot tr th,
    .woocommerce-table--order-details tfoot tr td,
    .woocommerce-table--order-details tbody tr td,
    .woocommerce-checkout-review-order-table tfoot tr th,
    .woocommerce-checkout-review-order-table tfoot tr td,
    .woocommerce-checkout-review-order-table tbody tr td {
      padding: 5px 8px !important; } }

.woocommerce-checkout-review-order-table {
  font-size: 0.9rem; }
  .woocommerce-checkout-review-order-table thead .product-total,
  .woocommerce-checkout-review-order-table thead .product-name {
    font-size: 1.1rem; }

.woocommerce-checkout-payment {
  background-color: #f4f4f4;
  border: 1px solid #707070;
  padding: 2rem;
  border-radius: 5px; }
  @media (max-width: 767px) {
    .woocommerce-checkout-payment {
      padding: 1rem; } }
  .woocommerce-checkout-payment ul.wc_payment_methods {
    list-style: none;
    padding-left: 0;
    margin-bottom: 2rem !important; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method {
      padding-right: 1rem;
      padding-bottom: 0.5rem; }
      @media screen and (max-width: 575px) {
        .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method {
          padding-bottom: 0.7rem;
          padding-right: 0; } }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method > label {
      font-weight: 700;
      display: inline; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method > label .about_paypal {
      font-weight: 400; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method > label > img {
      margin-left: 10px;
      margin-right: 10px; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method.payment_method_bizum img {
      width: 80px; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method img {
      max-width: 35px !important;
      height: auto; }
      .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method img:first-child {
        margin-left: 10px !important; }
    .woocommerce-checkout-payment ul.wc_payment_methods .input-radio {
      display: inline-block;
      width: auto;
      margin-right: 0.5rem; }
    .woocommerce-checkout-payment ul.wc_payment_methods .payment_box {
      width: 100%;
      margin: 10px 1.5rem 10px 1.5rem;
      padding: 0.5rem 1rem;
      font-size: 0.8rem;
      border-radius: 0.45rem;
      background-color: #fff;
      border: 1px solid #dbdbdb; }
      @media screen and (max-width: 575px) {
        .woocommerce-checkout-payment ul.wc_payment_methods .payment_box {
          margin-left: 0;
          margin-right: 0; } }
      .woocommerce-checkout-payment ul.wc_payment_methods .payment_box p {
        margin-bottom: 5px; }
    .woocommerce-checkout-payment ul.wc_payment_methods label {
      display: inline-flex;
      align-items: center;
      font-weight: 600;
      font-size: 0.8rem; }

@media screen and (max-width: 575px) {
  .payment_method_stripe {
    margin: 0rem !important;
    padding: 0rem !important; } }

.payment_method_stripe label[for="payment_method_stripe"] {
  width: 90% !important; }

.place-order button.button {
  background-color: #ff0066 !important;
  text-align: center;
  padding: 1.2rem 1.5rem;
  font-size: 1.2rem; }
  @media (max-width: 767px) {
    .place-order button.button {
      display: block !important;
      width: 100% !important; } }
  @media (min-width: 991px) {
    .place-order button.button {
      display: block !important;
      width: 100% !important; } }

.woocommerce-terms-and-conditions-wrapper .woocommerce-privacy-policy-text {
  font-size: 0.8rem; }
  .woocommerce-terms-and-conditions-wrapper .woocommerce-privacy-policy-text p {
    margin-bottom: 5px; }

.woocommerce-terms-and-conditions-wrapper a {
  color: #6c3e51; }

.woocommerce-terms-and-conditions-wrapper .woocommerce-terms-and-conditions {
  padding: 1rem;
  background-color: #fff; }
  .woocommerce-terms-and-conditions-wrapper .woocommerce-terms-and-conditions h2 {
    font-size: 1.3rem; }
  .woocommerce-terms-and-conditions-wrapper .woocommerce-terms-and-conditions h3 {
    font-size: 1.2rem; }
  .woocommerce-terms-and-conditions-wrapper .woocommerce-terms-and-conditions p {
    font-size: 0.8rem; }
  .woocommerce-terms-and-conditions-wrapper .woocommerce-terms-and-conditions h2,
  .woocommerce-terms-and-conditions-wrapper .woocommerce-terms-and-conditions h3,
  .woocommerce-terms-and-conditions-wrapper .woocommerce-terms-and-conditions h4,
  .woocommerce-terms-and-conditions-wrapper .woocommerce-terms-and-conditions h5,
  .woocommerce-terms-and-conditions-wrapper .woocommerce-terms-and-conditions ul,
  .woocommerce-terms-and-conditions-wrapper .woocommerce-terms-and-conditions ol,
  .woocommerce-terms-and-conditions-wrapper .woocommerce-terms-and-conditions table {
    margin-bottom: 1rem !important; }

.woocommerce-terms-and-conditions-checkbox-text {
  font-size: 0.9rem; }

/* Títulos ------------------------- */
.cart-empty,
.woocommerce-thankyou-order-received,
h2.wc-bacs-bank-details-heading,
h2.woocommerce-order-details__title {
  margin-top: 0;
  padding-top: 1rem;
  font-size: 2rem !important;
  color: #6c3e51;
  font-weight: 600;
  margin-bottom: 1rem; }
  @media (max-width: 767px) {
    .cart-empty,
    .woocommerce-thankyou-order-received,
    h2.wc-bacs-bank-details-heading,
    h2.woocommerce-order-details__title {
      font-size: 1.3rem !important; } }

.wc-bacs-bank-details-account-name {
  margin-top: 0; }

/* Margin bottoms ------------------------- */
.order_details,
.woocommerce-bacs-bank-details {
  margin-bottom: 3rem !important; }

.order_details,
.wc-bacs-bank-details,
.woocommerce-order-overview {
  list-style: none;
  padding: 0;
  background-color: #fff;
  padding: 1.5rem 1rem 1rem 1.5rem;
  display: flex;
  font-size: 0.9rem;
  border: 1px solid #4d4d4d;
  border-radius: 0.45rem; }
  @media (max-width: 767px) {
    .order_details,
    .wc-bacs-bank-details,
    .woocommerce-order-overview {
      flex-direction: column;
      padding: 1rem; } }
  .order_details li,
  .wc-bacs-bank-details li,
  .woocommerce-order-overview li {
    margin-right: 1.5rem;
    padding-right: 1.5rem;
    border-right: 1px solid #4d4d4d; }
    @media (max-width: 767px) {
      .order_details li,
      .wc-bacs-bank-details li,
      .woocommerce-order-overview li {
        border-right: 0; } }
  @media (max-width: 767px) {
    .order_details li:last-child,
    .wc-bacs-bank-details li:last-child,
    .woocommerce-order-overview li:last-child {
      margin-bottom: 0 !important; } }
  .order_details li strong,
  .wc-bacs-bank-details li strong,
  .woocommerce-order-overview li strong {
    display: block; }

/* Order recibed ------------------------- */
.woocommerce-table--order-details {
  display: block !important;
  box-shadow: initial; }

#nxshop_spot_selection {
  background-color: #ff0066;
  color: #fff; }
  @media (max-width: 767px) {
    #nxshop_spot_selection {
      display: block;
      text-align: center; } }

#nxshop_table_container {
  font-size: 0.85rem;
  margin-top: 1rem; }
  #nxshop_table_container table tbody tr td {
    vertical-align: middle !important; }
    #nxshop_table_container table tbody tr td p {
      margin-bottom: 0; }

.woocommerce-account-fields .create-account {
  padding: 1rem;
  border: 1px dashed #4d4d4d;
  border-radius: 1rem;
  color: #ff0066; }

.woocommerce-form-login-toggle {
  margin-bottom: 2rem; }

.page-template-page-cart .woocommerce-form-login {
  margin-bottom: 2rem; }
  .page-template-page-cart .woocommerce-form-login > p {
    font-size: 0.7rem; }
  .page-template-page-cart .woocommerce-form-login input {
    display: block; }
  .page-template-page-cart .woocommerce-form-login .form-row {
    display: block;
    width: 100%; }
  .page-template-page-cart .woocommerce-form-login .woocommerce-form__label-for-checkbox {
    display: flex; }

@font-face {
  font-family: 'WooCommerce';
  src: url("../fonts/WooCommerce.eot?jj1bj4");
  src: url("../fonts/WooCommerce.eot?jj1bj4#iefix") format("embedded-opentype"), url("../fonts/WooCommerce.ttf?jj1bj4") format("truetype"), url("../fonts/WooCommerce.woff?jj1bj4") format("woff"), url("../fonts/WooCommerce.svg?jj1bj4#WooCommerce") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="wcicon-"], [class*=" wcicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'WooCommerce' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.wcicon-storefront:before {
  content: "\e900"; }

.wcicon-ccv:before {
  content: "\e604"; }

.wcicon-virtual:before {
  content: "\e000"; }

.wcicon-up-down:before {
  content: "\e022"; }

.wcicon-reports:before {
  content: "\e023"; }

.wcicon-refresh:before {
  content: "\e031"; }

.wcicon-navigation:before {
  content: "\e032"; }

.wcicon-status-fill:before {
  content: "\e03c"; }

.wcicon-contract:before {
  content: "\e004"; }

.wcicon-downloadable:before {
  content: "\e001"; }

.wcicon-plus:before {
  content: "\e007"; }

.wcicon-simple:before {
  content: "\e006"; }

.wcicon-on-hold:before {
  content: "\e033"; }

.wcicon-external:before {
  content: "\e034"; }

.wcicon-contract-2:before {
  content: "\e036"; }

.wcicon-expand-2:before {
  content: "\e035"; }

.wcicon-phone:before {
  content: "\e037"; }

.wcicon-user:before {
  content: "\e038"; }

.wcicon-status:before {
  content: "\e039"; }

.wcicon-status-pending:before {
  content: "\e012"; }

.wcicon-status-cancelled:before {
  content: "\e013"; }

.wcicon-west:before {
  content: "\e02f"; }

.wcicon-south:before {
  content: "\e02e"; }

.wcicon-mail:before {
  content: "\e02d"; }

.wcicon-inventory:before {
  content: "\e02c"; }

.wcicon-attributes:before {
  content: "\e02b"; }

.wcicon-north:before {
  content: "\e02a"; }

.wcicon-east:before {
  content: "\e029"; }

.wcicon-note:before {
  content: "\e028"; }

.wcicon-windows:before {
  content: "\e027"; }

.wcicon-user2:before {
  content: "\e026"; }

.wcicon-search-2:before {
  content: "\e025"; }

.wcicon-search:before {
  content: "\e024"; }

.wcicon-star-empty:before {
  content: "\e021"; }

.wcicon-share:before {
  content: "\e030"; }

.wcicon-phone-fill:before {
  content: "\e03b"; }

.wcicon-woo:before {
  content: "\e03d"; }

.wcicon-user-fill:before {
  content: "\e03a"; }

.wcicon-grouped:before {
  content: "\e002"; }

.wcicon-status-refunded:before {
  content: "\e014"; }

.wcicon-status-completed:before {
  content: "\e015"; }

.wcicon-variable:before {
  content: "\e003"; }

.wcicon-expand:before {
  content: "\e005"; }

.wcicon-status-failed:before {
  content: "\e016"; }

.wcicon-check:before {
  content: "\e017"; }

.wcicon-right:before {
  content: "\e008"; }

.wcicon-up:before {
  content: "\e009"; }

.wcicon-query:before {
  content: "\e018"; }

.wcicon-down:before {
  content: "\e00a"; }

.wcicon-truck-1:before {
  content: "\e019"; }

.wcicon-left:before {
  content: "\e00b"; }

.wcicon-truck-2:before {
  content: "\e01a"; }

.wcicon-image:before {
  content: "\e00c"; }

.wcicon-globe:before {
  content: "\e01b"; }

.wcicon-link:before {
  content: "\e00d"; }

.wcicon-gear:before {
  content: "\e01c"; }

.wcicon-calendar:before {
  content: "\e00e"; }

.wcicon-cart:before {
  content: "\e01d"; }

.wcicon-processing:before {
  content: "\e00f"; }

.wcicon-card:before {
  content: "\e01e"; }

.wcicon-view:before {
  content: "\e010"; }

.wcicon-stats:before {
  content: "\e01f"; }

.wcicon-status-processing:before {
  content: "\e011"; }

.wcicon-star-full:before {
  content: "\e020"; }

.wcicon-coupon:before {
  content: "\e600"; }

.wcicon-limit:before {
  content: "\e601"; }

.wcicon-restricted:before {
  content: "\e602"; }

.wcicon-edit:before {
  content: "\e603"; }

textarea,
select,
input,
.qty {
  padding: 12px 12px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 16px;
  color: #4d4d4d; }
  @media (max-width: 575px) {
    textarea,
    select,
    input,
    .qty {
      font-size: 0.7rem;
      padding: 11px 11px; } }

.qty {
  text-align: center;
  width: 80px; }

.shop_table .qty {
  width: 70px; }

select {
  background: #fff url("../assets/ico/ico-arrow-down-pic.svg") no-repeat right center;
  background-size: 18px auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-position: right 15px top 10px;
  cursor: pointer;
  /* Hide default dropdown arrow */
  appearance: none;
  transition: 0.2s all ease-in-out;
  padding-right: 2rem !important;
  font-size: 0.8rem; }
  select.orderby {
    padding: 8px 10px;
    box-shadow: none; }
  select:hover {
    color: #6c3e51;
    transition: 0.2s all ease-in-out; }

.selection .select2-selection {
  height: 50px !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  box-shadow: inset 1px 1px 3px 0px rgba(0, 0, 0, 0.15); }

.selection .select2-selection__rendered {
  padding: 10px 14px; }

.selection .select2-selection__arrow {
  padding: 10px 12px;
  margin-top: 12px;
  margin-right: 5px; }

.selection .select2-selection__clear {
  margin-right: 1rem; }

.selection .select2-selection__arrow b {
  border-width: 10px 10px 0 10px; }

.qib-container .minus {
  border-radius: 4px 0 0 4px !important; }

.qib-container .plus {
  border-radius: 0 4px 4px 0 !important; }

.page-woocommerce {
  padding-top: 0rem;
  padding-bottom: 4rem; }
  .page-woocommerce--product {
    padding-top: 0; }

.term-description {
  margin-top: 2.5rem; }

ul.products {
  padding-left: 0;
  list-style: none;
  display: grid;
  width: 100%;
  margin-top: 1rem; }
  ul.products li.product {
    background-color: #fff;
    border-radius: 0.45rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    ul.products li.product .button {
      background: #252a32;
      border-color: #252a32;
      border-width: 2px !important;
      color: #fff;
      border-style: solid;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-align: center;
      transition: 0.2s all ease-in-out; }
      ul.products li.product .button:hover .bi {
        transition: 0.2s all ease-in-out;
        margin-right: 15px; }
      ul.products li.product .button .bi {
        margin-right: 5px;
        margin-bottom: 5px;
        transition: 0.2s all ease-in-out; }
  ul.products .product__cat {
    margin-bottom: 0.3rem;
    color: #4d4d4d;
    font-size: 0.75rem;
    letter-spacing: 0.5px; }
    @media (max-width: 575px) {
      ul.products .product__cat {
        margin-bottom: 0.2rem !important; } }
  ul.products .woocommerce-loop-product__link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
    position: relative; }
    @media (max-width: 575px) {
      ul.products .woocommerce-loop-product__link {
        align-items: flex-start; } }
    ul.products .woocommerce-loop-product__link .onsale {
      position: absolute;
      background-color: #ff0066;
      color: #fff;
      padding: 1px 3px 2px;
      border-radius: 3px;
      text-transform: uppercase;
      font-size: 0.85rem;
      font-weight: 600;
      top: 0.5rem;
      left: 0.5rem;
      z-index: 1;
      font-family: "Oswald", sans-serif; }
      @media screen and (max-width: 575px) {
        ul.products .woocommerce-loop-product__link .onsale {
          font-size: 0.7rem;
          padding: 2px 8px 3px; } }
  ul.products .woocommerce-loop-product__link .wp-post-image {
    width: 100%;
    height: auto;
    border-radius: 4px; }
  ul.products .attachment-woocommerce_thumbnail {
    width: 100% !important;
    height: auto;
    margin-bottom: 0.5rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1); }
    @media (max-width: 575px) {
      ul.products .attachment-woocommerce_thumbnail {
        margin-bottom: 0.5rem !important; } }
  ul.products .woocommerce-loop-product__title {
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
    display: block;
    color: #252a32;
    font-weight: 600;
    font-family: "Oswald", sans-serif;
    font-weight: 500;
    text-transform: uppercase; }
    @media (max-width: 575px) {
      ul.products .woocommerce-loop-product__title {
        font-size: 1rem; } }
  @media (max-width: 575px) {
    ul.products .price {
      width: 100%; } }
  ul.products .price .woocommerce-Price-amount {
    color: #4d4d4d;
    font-size: 0.9rem;
    font-family: "Oswald", sans-serif; }
  ul.products .price .woocommerce-Price-currencySymbol {
    font-size: 0.6rem; }
  ul.products .price ins {
    text-decoration: none !important; }
    ul.products .price ins bdi {
      color: red; }

.columns-4 {
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3rem 2rem; }
  @media (min-width: 768px) and (max-width: 991px) {
    .columns-4 {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 767px) {
    .columns-4 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 3rem 1rem; } }
  @media (max-width: 575px) {
    .columns-4 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 3rem 1rem; } }

@media screen and (max-width: 575px) {
  .home .page-woocommerce ul.products.columns-4 li.product:nth-child(3n) {
    grid-column: 1 / 3; } }

@media (max-width: 575px) {
  .page-template-front-page .columns-4,
  .term-zona-outlet .columns-4 {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem 1rem; } }

.columns-6 {
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 2rem; }
  @media (min-width: 768px) and (max-width: 991px) {
    .columns-6 {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 767px) {
    .columns-6 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem; } }
  @media (max-width: 480px) {
    .columns-6 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem; } }

.products li.product-category img {
  width: 100%;
  height: auto;
  border: 0px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem; }

.products li.product-category h2.woocommerce-loop-category__title {
  font-size: 1rem;
  color: #707070;
  text-align: center; }

.term-description {
  margin-bottom: 1rem;
  font-size: 0.85rem; }

.woocommerce-result-count {
  display: inline-block;
  font-size: 0.8rem;
  border-radius: 3px; }
  @media (max-width: 575px) {
    .woocommerce-result-count {
      font-size: 0.8rem;
      display: none; } }

.woocommerce-ordering {
  display: inline-block;
  float: right;
  clear: both; }
  @media (max-width: 991px) {
    .woocommerce-ordering {
      float: none; } }

/* Paginador -------------------- */
.woocommerce-pagination {
  text-align: center; }
  .woocommerce-pagination ul.page-numbers {
    list-style: none;
    display: inline-flex;
    justify-content: center;
    width: auto !important;
    border: 0px solid #ff0066;
    padding: 2px;
    border-radius: 3px; }
  .woocommerce-pagination li {
    display: inline-block; }
  .woocommerce-pagination li .page-numbers {
    padding: 10px 20px;
    background-color: #ff0066;
    color: #fff;
    margin: 2px;
    display: block;
    border-radius: 3px;
    font-size: 1.2rem; }
  .woocommerce-pagination li .page-numbers.current {
    background-color: #6c3e51; }
  .woocommerce-pagination li .page-numbers:hover {
    background-color: #6c3e51; }

.pepper-container-line {
  background-color: #f4f4f4;
  border-radius: 50px; }

.page-template-page-login.logged-out .bg-login {
  background: url("../assets/site/bg-login.jpg") center center;
  background-size: cover; }

.logged-out.page-template-page-login .woocommerce > h2 {
  display: none; }

.logged-out.page-template-page-login .u-columns .col-1 > h2,
.logged-out.page-template-page-login .u-columns .col-2 > h2 {
  color: #fff;
  text-align: center;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem; }

.woocommerce-form-register,
.woocommerce-form-login {
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem 2rem;
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1); }
  .woocommerce-form-register .woocommerce-privacy-policy-text p,
  .woocommerce-form-login .woocommerce-privacy-policy-text p {
    font-size: 0.7rem !important; }

.woocommerce-form-register label,
.woocommerce-form-row label,
.woocommerce-form-login label {
  display: block;
  margin-bottom: 8px;
  font-size: 0.9rem; }

.woocommerce-form-register input,
.woocommerce-form-row input,
.woocommerce-form-login input {
  display: block;
  width: 100%; }

.woocommerce-form-register__rememberme,
.woocommerce-form-row__rememberme,
.woocommerce-form-login__rememberme {
  padding-bottom: 1rem !important; }

.woocommerce-form-register__submit,
.woocommerce-form-row__submit,
.woocommerce-form-login__submit {
  display: block;
  width: 100%; }

.woocommerce-form-register .lost_password,
.woocommerce-form-row .lost_password,
.woocommerce-form-login .lost_password {
  font-size: 0.7rem;
  text-align: right; }

.page-template-page-login.logged-in .woocommerce {
  display: grid;
  grid-template-columns: 3fr 9fr 1fr;
  grid-gap: 3rem; }
  @media (max-width: 991px) {
    .page-template-page-login.logged-in .woocommerce {
      display: initial; } }

.woocommerce-MyAccount-navigation ul {
  list-style: none; }
  @media (max-width: 991px) {
    .woocommerce-MyAccount-navigation ul {
      display: flex;
      padding: 0;
      background-color: initial;
      margin-top: 0;
      flex-wrap: wrap; } }

.woocommerce-MyAccount-navigation li {
  margin-right: 1rem; }
  @media screen and (max-width: 991px) {
    .woocommerce-MyAccount-navigation li {
      margin-bottom: 3px; } }

@media (max-width: 991px) {
  .woocommerce-MyAccount-navigation li a {
    padding: 5px 1rem;
    background-color: #707070;
    border-radius: 50px;
    color: #fff;
    display: inline-flex; } }

@media (max-width: 767px) {
  .woocommerce-MyAccount-navigation li a {
    font-size: 0.7rem;
    color: #4d4d4d;
    color: #fff; } }

.woocommerce-MyAccount-navigation li.is-active a {
  color: #4d4d4d; }
  @media (max-width: 991px) {
    .woocommerce-MyAccount-navigation li.is-active a {
      font-size: 0.7rem;
      color: #fff;
      background-color: #fda11e; } }

.woocommerce-MyAccount-content .woocommerce-table--order-details {
  display: block; }

.woocommerce-MyAccount-content .woocommerce-Message .button {
  display: inline-block; }

.woocommerce-EditAccountForm .woocommerce-form-row input {
  width: auto; }

.woocommerce-EditAccountForm span em {
  font-size: 0.7rem; }

.account-orders-table {
  font-size: 0.9rem;
  border-collapse: collapse; }
  @media (max-width: 767px) {
    .account-orders-table thead {
      display: none; } }
  @media (max-width: 767px) {
    .account-orders-table tbody {
      font-size: 0.7rem; } }
  @media (max-width: 767px) {
    .account-orders-table tbody tr {
      display: block;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      margin-bottom: 1rem;
      padding: 1rem;
      border-radius: 0.45rem; } }
  .account-orders-table tbody td {
    text-align: center;
    padding: 10px 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 0;
    border-right: 0; }
    @media (max-width: 767px) {
      .account-orders-table tbody td {
        text-align: right;
        padding: 8px 5px; } }
  .account-orders-table tbody .view {
    padding: 6px 1rem;
    display: block; }
  .account-orders-table .woocommerce-orders-table__cell-order-status {
    font-weight: 600; }
  .account-orders-table .woocommerce-orders-table__cell-order-actions .view {
    display: inline-block; }

div[data-title="Cancelado"] {
  color: #760e0e; }

.woocommerce-notices-wrapper {
  display: block;
  padding-top: 1rem;
  margin-bottom: 3rem; }
  @media screen and (max-width: 575px) {
    .woocommerce-notices-wrapper {
      margin-bottom: 1rem; } }
  .woocommerce-notices-wrapper:empty {
    margin-bottom: 0;
    padding: 0; }
  .woocommerce-notices-wrapper .woocommerce-error li {
    color: red; }
  .woocommerce-notices-wrapper .woocommerce-message {
    display: block;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.45rem;
    font-size: 1.2rem;
    text-align: center;
    font-weight: 600;
    overflow: hidden; }
    @media (max-width: 575px) {
      .woocommerce-notices-wrapper .woocommerce-message {
        display: block;
        font-size: 0.8rem;
        text-align: left;
        background-color: #faffd0;
        border: 1px solid #b5bb84;
        border-radius: 0;
        padding: 1rem;
        box-shadow: none;
        text-align: center; } }
    .woocommerce-notices-wrapper .woocommerce-message a.button {
      text-align: center; }
      @media (max-width: 575px) {
        .woocommerce-notices-wrapper .woocommerce-message a.button {
          float: left;
          display: inline-block;
          padding: 0.85rem 1rem;
          width: 100%;
          height: auto;
          margin-bottom: 0.5rem; } }
    @media (max-width: 575px) {
      .woocommerce-notices-wrapper .woocommerce-message a.restore-item {
        height: auto;
        display: inline; } }
  .woocommerce-notices-wrapper .button {
    display: inline-block;
    background-color: #6c3e51;
    margin-right: 2rem;
    font-weight: normal; }
    @media (max-width: 575px) {
      .woocommerce-notices-wrapper .button {
        width: 230px;
        margin-right: 1rem; } }
    .woocommerce-notices-wrapper .button:hover {
      color: #fff; }

.woocommerce-NoticeGroup-checkout .woocommerce-error li {
  color: #880f0f; }

div.product {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-auto-rows: auto;
  column-gap: 2.5rem;
  row-gap: 5rem;
  position: relative; }
  @media (max-width: 767px) {
    div.product {
      display: block; } }
  div.product .woocommerce-product-gallery {
    grid-column: 1 / 6;
    grid-row: 1 / 2;
    position: relative; }
  div.product .summary {
    grid-column: 6 / 10;
    grid-row: 1 / 2; }
    @media screen and (max-width: 1199px) {
      div.product .summary {
        grid-column: 6 / 11; } }
  div.product .woocommerce-tabs {
    grid-column: 1 / 6;
    grid-row: 2 / 3; }
  div.product .related {
    grid-column: 6 / 11;
    grid-row: 2 / 3; }
  div.product .up-sells {
    grid-column: 1 / 11;
    grid-row: 3 / 4; }
  div.product .onsale {
    background-color: #ff0066;
    position: absolute;
    display: inline-block;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-weight: bold !important;
    z-index: 50;
    top: 2rem;
    left: 2rem;
    color: #fff;
    padding: 5px 10px;
    border-radius: 50px; }
    @media screen and (max-width: 575px) {
      div.product .onsale {
        top: 1rem;
        left: 1rem; } }

@media (max-width: 767px) {
  .summary {
    margin-bottom: 3rem !important; } }

@media screen and (max-width: 575px) {
  .summary {
    margin-bottom: 3rem; } }

.summary .product_title {
  margin-top: 0;
  padding-top: 0;
  color: #252a32;
  font-size: 1.5rem !important;
  font-weight: 600;
  margin-bottom: 0rem;
  color: #252a32; }
  @media (max-width: 575px) {
    .summary .product_title {
      font-size: 1.4rem !important;
      margin-bottom: 0.3rem; } }

.summary .price {
  font-size: 1.5rem !important; }
  @media (max-width: 575px) {
    .summary .price {
      font-size: 1.2rem !important; } }

.summary ins {
  text-decoration: none !important; }
  .summary ins bdi {
    color: red; }

.summary del {
  margin-right: 0.5rem; }
  .summary del bdi {
    font-size: 80%; }

.summary .stock.out-of-stock {
  color: red;
  font-weight: 600; }

.summary .screen-reader-text {
  display: none; }

.summary .cart.variations_form {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem; }

.summary .cart {
  margin-bottom: 2rem;
  display: flex; }

.summary .cart .qty {
  padding: 15px 10px;
  font-size: 1rem;
  text-align: center;
  box-shadow: none; }
  @media (max-width: 575px) {
    .summary .cart .qty {
      padding: 10px 5px;
      width: 80px; } }

.summary .quantity {
  margin-right: 1rem; }
  .summary .quantity.hidden {
    display: none; }

.summary .single_add_to_cart_button {
  display: flex;
  justify-content: center;
  width: 100%; }

.summary .woocommerce-variation-add-to-cart {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.ncdgw_textarea_wrapper {
  margin-top: 0.5rem; }

.product_meta {
  font-size: 0.8rem;
  margin-bottom: 1rem;
  display: none; }
  @media screen and (max-width: 767px) {
    .product_meta {
      display: none; } }
  .product_meta > span {
    display: block; }
  .product_meta a {
    color: #252a32;
    text-decoration: underline; }

.summary .price {
  margin: 0 0 1rem;
  font-weight: normal;
  color: #252a32;
  font-size: 2rem; }
  @media (max-width: 575px) {
    .summary .price {
      border: none;
      margin: 0 0 1rem;
      padding: 0; } }
  @media (max-width: 390px) {
    .summary .price {
      margin: 0 0 0.5rem; } }

.summary .woocommerce-product-details__short-description {
  margin-bottom: 1.4rem;
  font-size: 0.9rem;
  color: #4d4d4d; }

.summary table.variations label {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.7rem;
  letter-spacing: 1px;
  color: #252a32; }

.summary table.variations .reset_variations {
  font-size: 12px;
  color: red; }

.guia-tallas {
  margin-bottom: 10px; }
  .guia-tallas__link {
    cursor: pointer; }
    @media screen and (max-width: 575px) {
      .guia-tallas__link {
        font-size: 0.9rem; } }

.cinta {
  background-color: rgba(0, 0, 0, 0.02);
  padding: 0rem 0rem;
  border-radius: 0.45rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2); }
  .cinta__ico {
    margin-right: 2rem; }
    .cinta__ico img {
      width: 70px;
      height: auto; }
      @media screen and (max-width: 767px) {
        .cinta__ico img {
          width: 90px; } }
  .cinta__content {
    padding-right: 1rem; }

.coleccion {
  margin-bottom: 2rem; }
  .coleccion__item {
    display: grid;
    grid-template-columns: 60px 50px auto 70px;
    grid-gap: 1rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 4px; }
  .coleccion__foto img {
    width: 50px;
    height: auto;
    border-radius: 2px; }
  .coleccion__quantity label {
    display: none; }
  .coleccion__quantity .quantity input {
    width: 100%;
    padding: 0.75rem 0.5rem !important;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: #4d4d4d;
    min-width: 50px; }
  .coleccion__nombre .precio-metro {
    font-size: 0.8rem;
    background-color: #707070;
    color: rgba(0, 0, 0, 0.6);
    padding: 1px 4px;
    border-radius: 3px; }
  .coleccion__label label {
    display: flex;
    margin-bottom: 3px; }
  .coleccion__label label a {
    font-size: 13px;
    font-weight: 400;
    color: #252a32; }
  .coleccion__price {
    text-align: right;
    font-size: 1rem;
    color: #ff0066;
    font-weight: 700; }
    .coleccion__price .stock {
      margin: 0;
      font-size: 0.8rem;
      color: #4d4d4d; }

.garantia-extras {
  font-size: 0.8rem; }
  .garantia-extras a {
    color: #252a32;
    border-bottom: 1px solid;
    font-weight: normal; }
  .garantia-extras__nav {
    list-style: none;
    padding-left: 0; }
  .garantia-extras__item {
    margin-bottom: 8px; }
    @media (max-width: 575px) {
      .garantia-extras__item {
        margin-bottom: 6px; } }
    .garantia-extras__item .bi {
      margin-right: 5px; }
    .garantia-extras__item--share {
      padding-bottom: 8px; }
      .garantia-extras__item--share a {
        border-bottom: 0;
        font-weight: 600;
        color: #128c7e; }
  .garantia-extras a.verde-whatsapp {
    color: #128c7e; }
    @media (max-width: 575px) {
      .garantia-extras a.verde-whatsapp {
        background-color: #128c7e;
        color: #fff;
        display: inline-block;
        border-radius: 50px;
        padding: 0 8px;
        text-decoration: none; } }

@media (max-width: 767px) {
  .woocommerce-tabs {
    margin-bottom: 6rem; } }

@media screen and (max-width: 575px) {
  .woocommerce-tabs {
    background-color: #f4f4f4;
    padding-top: 2rem;
    padding-bottom: 1rem;
    padding-left: 12px;
    padding-right: 12px;
    margin-left: -11px;
    margin-right: -11px; } }

.woocommerce-tabs .wc-tab {
  padding-top: 1rem;
  border-radius: 0.45rem; }
  @media screen and (max-width: 575px) {
    .woocommerce-tabs .wc-tab {
      padding-top: 1rem; } }
  .woocommerce-tabs .wc-tab h2,
  .woocommerce-tabs .wc-tab h3,
  .woocommerce-tabs .wc-tab h4,
  .woocommerce-tabs .wc-tab ul,
  .woocommerce-tabs .wc-tab ol {
    margin-bottom: 1.5rem; }
  .woocommerce-tabs .wc-tab h2 {
    font-size: 1.5rem; }
  .woocommerce-tabs .wc-tab h3 {
    font-size: 1.3rem; }
  .woocommerce-tabs .wc-tab p {
    margin-bottom: 1.2rem; }
  .woocommerce-tabs .wc-tab h2:first-child {
    margin-top: 0;
    font-size: 1rem;
    letter-spacing: 1px;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .woocommerce-tabs .wc-tab h2:first-child {
        display: none; } }

.woocommerce-tabs .tabs {
  padding-left: 0;
  list-style: none;
  display: flex; }
  @media screen and (max-width: 575px) {
    .woocommerce-tabs .tabs {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0.3rem; } }
  @media screen and (min-width: 576px) {
    .woocommerce-tabs .tabs li {
      margin-right: 0.3rem; } }
  .woocommerce-tabs .tabs li a {
    display: block;
    position: relative;
    padding: 4px 8px;
    background-color: rgba(0, 0, 0, 0.2);
    color: #252a32;
    border-radius: 4px; }
    @media screen and (max-width: 575px) {
      .woocommerce-tabs .tabs li a {
        text-align: center; } }
    @media screen and (max-width: 380px) {
      .woocommerce-tabs .tabs li a {
        padding: 4px 6px !important;
        font-size: 0.75rem; } }
    .woocommerce-tabs .tabs li a:hover {
      background-color: #707070;
      color: #fff; }
  .woocommerce-tabs .tabs li.active a {
    color: #fff;
    background-color: #4d4d4d;
    position: relative; }

.comment-form-email label,
.comment-form-author label {
  display: block; }

.wp-comment-cookies-consent label {
  font-size: 0.7rem !important; }

.woocommerce-product-attributes {
  width: 100%; }
  @media screen and (max-width: 575px) {
    .woocommerce-product-attributes-item {
      font-size: 0.8rem; } }
  .woocommerce-product-attributes-item__label {
    background-color: #f4f4f4;
    width: 25%;
    text-align: right;
    padding-right: 1rem; }
    @media screen and (max-width: 575px) {
      .woocommerce-product-attributes-item__label {
        padding-right: 0;
        text-align: center; } }
  .woocommerce-product-attributes-item__value {
    padding-left: 1rem;
    background-color: rgba(0, 0, 0, 0.05); }
    @media screen and (max-width: 575px) {
      .woocommerce-product-attributes-item__value {
        padding-left: 0;
        text-align: center; } }

.woocommerce-product-gallery {
  position: relative;
  margin-bottom: 1rem; }
  .woocommerce-product-gallery__trigger {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 10; }
  .woocommerce-product-gallery__wrapper {
    min-width: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 0.175rem; }
  .woocommerce-product-gallery__image {
    margin: 0;
    padding: 0;
    overflow: hidden;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    aspect-ratio: 1 / 1; }
    .woocommerce-product-gallery__image img {
      width: 100% !important;
      max-width: 100% !important;
      height: auto; }
    .woocommerce-product-gallery__image:first-child {
      width: 100%;
      display: block;
      margin-right: 0;
      grid-column: 1 / -1; }
  .woocommerce-product-gallery .wp-post-image {
    width: 100% !important;
    height: auto !important;
    border: 1px solid rgba(0, 0, 0, 0.1); }

.x-container {
  display: flex;
  overflow-y: auto; }

.products.related > h2,
.products.upsells > h2 {
  margin-bottom: 1.2rem !important;
  font-size: 1.2rem; }

section.related ul.products {
  display: grid;
  grid-template-columns: 1fr 1fr; }
  section.related ul.products li.product {
    display: block;
    width: 100%;
    height: auto;
    clear: both; }
  section.related ul.products .attachment-woocommerce_thumbnail {
    float: left !important;
    width: 100% !important;
    height: auto;
    margin-right: 1rem; }
  section.related ul.products .woocommerce-loop-product__link {
    width: 100%;
    align-items: inherit;
    display: block !important;
    height: auto; }
  section.related ul.products .woocommerce-loop-product__title {
    font-size: 0.9rem;
    width: auto; }

.garantia {
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  background-color: rgba(0, 0, 0, 0.04);
  margin-bottom: 1rem;
  border-radius: 5px; }
  @media screen and (max-width: 767px) {
    .garantia {
      margin-top: 0.9rem; } }
  .garantia__item {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    flex: 1 0 30%; }
    @media screen and (max-width: 767px) {
      .garantia__item {
        font-size: 0.7rem;
        line-height: 1.2; } }
  .garantia__ico {
    margin-right: 10px; }
    .garantia__ico .bi {
      font-size: 1.5rem;
      color: #4d4d4d;
      color: #6c3e51; }

.opciones {
  margin-bottom: 1rem; }
  .opciones__item {
    margin-bottom: 5px; }
    .opciones__item--tiempo {
      background-color: #aefcd7;
      color: #4d4d4d;
      display: inline-block;
      padding: 1px 10px;
      border-radius: 4px; }
    .opciones__item--kit span {
      padding-left: 10px; }

.descargas {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; }
  @media screen and (max-width: 575px) {
    .descargas {
      gap: 6px; } }
  .descargas__item {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #707070;
    color: #fff;
    font-size: 0.8rem; }
    @media screen and (max-width: 575px) {
      .descargas__item {
        font-size: 0.7rem;
        padding: 4px 8px; } }
    .descargas__item .bi {
      padding-right: 5px; }
      @media screen and (max-width: 575px) {
        .descargas__item .bi {
          padding-right: 3px; } }
    .descargas__item:hover {
      background-color: #4d4d4d;
      color: #fff; }

#review_form .comment-reply-title {
  font-size: 0.7rem;
  margin-bottom: 1rem;
  display: block; }

.product .star-rating span:before,
.star-rating::before {
  color: #ff0066 !important;
  border-color: #ff0066 !important; }

.woocommerce-product-rating .woocommerce-review-link {
  font-size: 0.7rem;
  color: #252a32; }

.presupuesto {
  padding: 1.5rem;
  background-color: #f7f7e7;
  margin-bottom: 1rem; }
  .presupuesto__titulo {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 600; }
  .presupuesto .wpcf7 {
    margin-bottom: 0; }
    .presupuesto .wpcf7 textarea {
      height: 5em; }

/**
	 * Star ratings
	 */
.star-rating {
  float: right;
  overflow: hidden;
  position: relative;
  height: 1em;
  line-height: 1;
  font-size: 1em;
  width: 5.4em;
  font-family: "WooCommerce"; }
  .star-rating::before {
    content: "\e020\e020\e020\e020\e020";
    float: left;
    top: 0;
    left: 0;
    position: absolute; }
  .star-rating span {
    overflow: hidden;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    padding-top: 1.5em; }
  .star-rating span::before {
    content: "\e021\e021\e021\e021\e021";
    top: 0;
    position: absolute;
    left: 0; }

.woocommerce-product-rating {
  line-height: 2;
  display: block; }
  .woocommerce-product-rating .star-rating {
    margin: 0.5em 4px 0 0;
    float: left; }

.products .star-rating {
  display: block;
  margin: 0 0 0.5em;
  float: none; }

.hreview-aggregate .star-rating {
  margin: 10px 0 0; }

#review_form #respond {
  position: static;
  margin: 0;
  width: auto;
  padding: 0;
  background: transparent none;
  border: 0; }
  #review_form #respond p {
    margin: 0 0 10px; }
  #review_form #respond .form-submit input {
    left: auto; }
  #review_form #respond textarea {
    box-sizing: border-box;
    width: 100%; }

p.stars a {
  position: relative;
  height: 1em;
  width: 1em;
  text-indent: -999em;
  display: inline-block;
  text-decoration: none; }
  p.stars a::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
    height: 1em;
    line-height: 1;
    font-family: "WooCommerce";
    content: "\e021";
    text-indent: 0; }
  p.stars a:hover ~ a::before {
    content: "\e021"; }

p.stars:hover a::before {
  content: "\e020"; }

p.stars.selected a.active::before {
  content: "\e020"; }

p.stars.selected a.active ~ a::before {
  content: "\e021"; }

p.stars.selected a:not(.active)::before {
  content: "\e020"; }

.commentlist {
  list-style: none;
  padding-left: 0; }
  .commentlist .comment_container {
    width: 100%;
    display: flex;
    gap: 1rem;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 5px;
    margin-bottom: 5px !important; }
    .commentlist .comment_container .avatar {
      width: 40px;
      height: 40px;
      display: none; }
    .commentlist .comment_container .comment-text {
      width: 100%; }
      .commentlist .comment_container .comment-text .star-rating {
        float: none; }
      .commentlist .comment_container .comment-text .meta {
        margin-top: .3rem;
        font-size: 0.9rem;
        margin-bottom: .3rem !important; }
      .commentlist .comment_container .comment-text .description p {
        margin-top: 0; }

.wc-block-cart-item__quantity {
  display: flex;
  align-items: center;
  gap: 0.9rem; }

.wc-block-checkout__terms {
  font-size: 0.7rem; }
  .wc-block-checkout__terms a {
    text-decoration: underline; }

.wc-block-components-validation-error p {
  font-size: 0.7rem !important; }

.wc-block-components-radio-control__option-checked .wc-block-components-radio-control__label {
  color: #ff0066; }

.wc-block-components-radio-control .wc-block-components-radio-control-accordion-content {
  font-size: 0.8rem; }

.wc-block-components-checkout-step__title {
  color: #6c3e51; }

.wp-block-woocommerce-empty-cart-block .wc-block-cart__empty-cart__title {
  border: dashed 1px #b6b6b6;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
  gap: 1.5rem !important; }
  @media screen and (max-width: 575px) {
    .wp-block-woocommerce-empty-cart-block .wc-block-cart__empty-cart__title {
      gap: 0px !important; } }

@media screen and (max-width: 575px) {
  .wp-block-woocommerce-empty-cart-block h2 {
    font-size: 0.9rem; } }

.wp-block-woocommerce-empty-cart-block h2:before {
  background-color: #b6b6b6 !important;
  margin: 0 !important;
  width: 80px !important;
  mask-size: 80px !important; }
  @media screen and (max-width: 575px) {
    .wp-block-woocommerce-empty-cart-block h2:before {
      width: 50px !important;
      mask-size: 40px !important;
      margin-left: 1rem !important; } }

.wp-block-woocommerce-empty-cart-block p {
  color: #fff; }
  .wp-block-woocommerce-empty-cart-block p a {
    display: inline-block;
    padding: 0.5rem 2rem;
    background-color: #ff0066;
    border-radius: 100px;
    color: #fff;
    font-size: 0.8rem; }
    @media screen and (min-width: 575px) {
      .wp-block-woocommerce-empty-cart-block p a {
        margin-bottom: 2.5rem; } }

.wp-block-woocommerce-empty-cart-block .wp-block-separator {
  display: none; }

@media screen and (max-width: 575px) {
  .wc-block-grid__products {
    display: grid !important;
    grid-template-columns: 1fr 1fr; } }

@media screen and (max-width: 575px) {
  .wp-block-woocommerce-cart-cross-sells-block {
    margin-top: 1.5rem; } }

@media screen and (max-width: 575px) {
  .wp-block-woocommerce-cart-cross-sells-block h2 {
    font-size: 1.25rem !important; } }

@media screen and (max-width: 575px) {
  .wp-block-woocommerce-cart-cross-sells-block div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem; } }

@media screen and (max-width: 575px) {
  .cross-sells-product div img {
    margin-bottom: calc($space-1/2); } }

@media screen and (max-width: 575px) {
  .cross-sells-product div {
    display: inline; } }

.wc-block-components-product-title {
  margin-bottom: calc($space-1/2) !important;
  color: #ff0066 !important; }

#div-redsys input[type="button"],
.wc-block-components-checkout-place-order-button,
.wc-block-components-button {
  background-color: #ff0066;
  border-color: #ff0066;
  color: #fff !important;
  border-radius: 0.45rem;
  font-size: 1.2rem;
  font-weight: 500;
  transition: 0.2s all ease-in-out; }
  #div-redsys input[type="button"]:hover,
  .wc-block-components-checkout-place-order-button:hover,
  .wc-block-components-button:hover {
    background: #6c3e51;
    color: #fff;
    transition: 0.2s all ease-in-out; }

.woocommerce-store-notice.demo_store {
  text-align: center;
  position: fixed;
  bottom: 0;
  background: #7f7f7f;
  color: #fff;
  font-size: 0.7rem;
  width: 100%;
  padding: 0.7rem;
  margin-bottom: 0; }
  .woocommerce-store-notice.demo_store a {
    text-decoration: underline;
    color: #fff; }
