.header-top {
  text-align: color;
  background-color: $color-principal-light;
  padding: 9px 0;
  font-size: $font-size-small;
  @media screen and (max-width: 767px) {
    display: none;
  }
  &__item {
    margin: 0 1rem;
    display: inline-block;
  }
  &__claim {
    font-weight: $font-weight-bold;
  }

  &__whatsapp {
    a {
      padding-left: 0.9rem;
    }
  }
}

.header-nav {
  position: relative;
  z-index: 9;
  background-color: $color-background;
  transition: $trans;
  @media screen and (max-width: 991px) {
    display: none !important;
  }
  &--sticky {
    background-color: $color-principal-light;
    transition: $trans;
  }
}

.header {
  z-index: 10;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transition: $trans;

  width: 100%;
  position: sticky;
  top: 0;
  @media screen and (max-width: 1419px) {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
  @media screen and (max-width: 767px) {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }
  @media screen and (max-width: 767px) {
    position: sticky;
    top: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  @media screen and (max-width: 575px) {
    flex-wrap: wrap;
  }

  &__container {
    display: flex;
    justify-content: space-between;
  }

  &--home {
    background-color: $color-background;
  }

  &--sticky {
    background-color: $color-background;
    box-shadow: $box-shadow;
    transition: $trans;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    @media screen and (max-width: 767px) {
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
    }
    .header__logo {
    }
  }

  &__left {
    display: flex;
    align-items: center;
    @media screen and (max-width: 575px) {
      flex: 0 0 50%;
    }
  }

  &__right {
    flex: 1 1 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media screen and (max-width: 575px) {
      flex: 0 0 40%;
    }
    &__item {
      margin-left: 1rem;
      &--shipping {
        img {
          width: 110px;
          height: auto;
          @media screen and (max-width: 1199px) {
            width: 100px;
          }
        }
        @media screen and (max-width: 991px) {
          display: none;
        }
      }
      &--search {
        @media screen and (max-width: 991px) {
          display: none;
        }
      }
      &--searchm {
        @media screen and (min-width: 768px) {
          display: none;
        }
      }
    }
    .wpml-ls-sidebars-wpml-top {
      margin-left: 2rem !important;
    }
  }

  &__shipping {
    flex: 1 1 100%;
    font-size: 0.75rem;
    text-align: right;
    span {
      background-color: $color-alternativo;
      color: $color-background;
      padding: 1px 8px;
      font-weight: 500;
      border-radius: 50px;
    }
    @media screen and (min-width: 576px) {
      display: none;
    }
  }

  &__burger {
    width: 50px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 480px) {
      with: 40px;
    }
  }

  &__logo {
    display: inline-flex;

    img {
      width: 170px;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 140px;
      }
      @media screen and (max-width: 480px) {
        width: 120px;
      }
      @media screen and (max-width: 360px) {
        width: 100px;
      }
    }
  }

  &__promo {
    @media screen and (max-width: 991px) {
      display: none !important;
    }
  }

  .bi {
    font-size: 1.8rem;
    @media screen and (max-width: 575px) {
      font-size: 1.5rem;
    }
  }

  .ico-svg {
    width: 28px !important;
    height: 28px !important;
  }

  &__search {
    margin-top: 4px;
    .woocommerce-product-search {
      position: relative;
      input[type="search"] {
        width: 100%;
      }
      button[type="submit"] {
        position: absolute;
        inset: 5px;
        left: auto;
      }
    }
    @media screen and (min-width: 992px) {
      display: none;
    }
  }
}

.wpml-ls-sidebars-wpml-top.wpml-ls-legacy-list-horizontal {
  border: 0;
  padding: 0;
  margin: 0 !important;
  li.wpml-ls-current-language {
    a {
      color: $color-principal !important;
      padding: 0px 10px 0px;
      font-weight: $font-weight-bold;
    }
  }

  a.wpml-ls-link {
    margin-right: 0px;
    padding-right: 0px;
    line-height: initial;
  }
}

.overlay-search {
  position: fixed;
  z-index: 500;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: $color-gris-04;
  display: none;
  @media screen and (min-width: 768px) {
    display: none;
  }
  &__container {
    padding: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    h2 {
      color: #fff;
      margin-top: 0;
    }
  }

  &__logo {
    width: 120px;
    margin-bottom: 2rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  &__link {
    margin-top: 2rem;
    text-align: center;
    @media screen and (max-width: 575px) {
      margin-bottom: 6rem;
    }
    span {
      color: $color-background;
      font-size: $font-size-small;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      .bi {
        margin-right: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .product-search {
    @media screen and (max-width: 767px) {
    }
    &-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    &-field {
      flex: 0 0 100%;
      margin-bottom: 1rem;
      width: 100%;
      text-align: center;
      font-size: 1rem;
      &:focus {
        outline: none;
      }
      &::placeholder {
      }
    }
    button[type="submit"] {
      cursor: pointer;
      .bi {
        margin-right: 5px;
      }
    }
  }
}

.woocommerce-demo-store {
  p.demo_store {
    position: fixed;
    height: auto;
    line-height: 19px;
    @media screen and (max-width: 767px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  header.h {
    @media screen and (max-width: 767px) {
      top: 62px;
    }
  }
}

// home page-template page-template-woocommerce page-template-woocommerce-php page page-id-16 wp-embed-responsive theme-calsilla woocommerce-demo-store woocommerce-js logged-out
// home page-template page-template-woocommerce page-template-woocommerce-php page page-id-16 wp-embed-responsive theme-calsilla woocommerce-demo-store woocommerce-js logged-out

.woocommerce-store-notice,
p.demo_store {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  width: 100%;
  font-size: 0.9rem;
  padding: 1em 0;
  text-align: center;
  background-color: $color-gris-04;
  background: $color-principal;
  color: $color-background;
  z-index: 99998;
  box-shadow: 0 1px 1em rgba(0, 0, 0, 0.2);
  display: none;
  @media screen and (max-width: 767px) {
    font-size: 0.8rem;
  }
  a {
    color: $color-background;
    text-decoration: underline;
  }
}

.woocommerce-store-notice__dismiss-link {
  font-weight: $font-weight-bold;
  margin-left: 10px;
}

.nav-promo-resp {
  padding: $font-size;
  @media screen and (min-width: 768px) {
    display: none;
  }
  &__items {
    margin: 0;
    padding: 0;
    display: flex;
    gap: $font-size;
    justify-content: center;
    align-items: center;
    list-style: none;
    width: 100%;
  }
  .menu-item {
    flex: 1;
    a {
      font-weight: $font-weight-bold;
      background-color: $color-gris-01;
      display: block;
      text-align: center;
      width: 100%;
      padding: $font-size-smaller / 2;
      border: 1px solid $color-gris-02;
      border-radius: $border-radius;
    }
  }
}
