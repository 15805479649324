.lookbook {
  display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  // grid-auto-rows: auto;
  gap: 2rem;
  margin-bottom: 100px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  &__item {
    display: flex;
    align-items: center;
    // background-color: rgba($color: #000000, $alpha: 0.03);
  }

  &__img {
    width: 100%;
    height: auto;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.03);
  }
}
