@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500&display=swap");

@import "base/variables";
@import "base/body";
@import "base/utilities";
@import "base/typography";
@import "base/mixins";

@import "layout/header";
@import "layout/footer";
@import "layout/nav";
@import "layout/widgets";

@import "components/page-title";
@import "components/buttons";
@import "components/articles";
@import "components/forms";
@import "components/cookies";

@import "pages/inicio";
@import "pages/contacto";
@import "pages/nosaltres";
@import "pages/lookbook";
@import "pages/legal";

@import "woo/buttons";
@import "woo/cart";
@import "woo/checkout";
@import "woo/fonts";
@import "woo/forms";
@import "woo/grid-woo";
@import "woo/login";
@import "woo/notifications";
@import "woo/product";
@import "woo/ratings";
@import "woo/comments";
@import "woo/block-woo-cart";
