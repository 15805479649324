/* SCSS Headers
--------------------------------------------------- */
.h2-header {
}

.h3-header {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  @media screen and (max-width: 575px) {
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 375px) {
  }
}

.h3-sections {
  font-size: 1.5rem;
  // font-weight: $font-weight-bold;
  margin-bottom: 1.5rem;
  @media screen and (max-width: 575px) {
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
  }
  &--center {
    text-align: center;
  }

  &--underline {
    margin-bottom: 2rem;

    &::after {
      content: "";
      display: block;
      width: 8rem;
      height: 2px;
      border-radius: 50px;
      margin-top: 0.5rem;
      margin-left: auto;
      margin-right: auto;
      background-color: #252a32;
      @media screen and (max-width: 575px) {
        height: 2px;
        width: 6rem;
        background-color: $color-principal-dark;
      }
    }
  }
}
