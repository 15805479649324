#cookie-law-info-bar {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
  @media screen and (max-width: 767px) {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important;
  }
  #cookie_action_close_header {
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 4px;
    @media screen and (max-width: 767px) {
      padding: 10px 1.2rem 11px !important;
    }
  }

  .cli-plugin-main-link {
    font-size: $font-size-small;
  }
  .cookie-actions {
    margin-top: 0.5rem;
    display: inline-block;
  }
}
