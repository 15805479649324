.hero {
  background-color: #ebeae8;
  position: relative;
  @media screen and (max-width: 575px) {
    // height: 100vh;
    display: none;
  }

  &__overlay {
    top: 0;
    bottom: 0;
    width: 100%;
    position: absolute;
    z-index: 1;
    background: rgba(0, 0, 0, 0.4);
    background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    @media screen and (max-width: 767px) {
      background-color: rgba(0, 0, 0, 0.4);
      top: 0;
      bottom: 0;
      width: 100%;
      position: absolute;
      z-index: 0;
    }
  }

  &__img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  &__bg {
    height: 600px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 767px) {
      height: 400px;
    }
    @media screen and (max-width: 575px) {
      height: 250px;
      align-items: flex-end;
      padding-bottom: 1.5rem;
    }
  }

  &__content {
    color: $color-background;
    width: 50%;
    position: relative;
    z-index: 1;
    @media screen and (max-width: 991px) {
      width: 80%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      text-align: center;
    }
  }

  &__pretitulo {
    font-size: 1.2rem;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 767px) {
      font-size: 0.9rem;
      text-transform: uppercase;
    }
  }
  &__titulo {
    font-weight: $font-weight-bold;
    font-size: 3rem;
    @media screen and (max-width: 767px) {
      font-size: 2rem;
    }
  }

  &__link {
    display: inline-block;
    margin-bottom: 3rem;
  }
}

.home .g-estilos {
  margin-bottom: 3rem;
}

.g-estilos {
  margin-top: 1px;
  &__items {
    display: flex !important;
    gap: 1px;
    @media screen and (max-width: 1199px) {
      display: grid !important;
      gap: 1px;
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    flex: 1;
    display: flex !important;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;
    overflow: hidden;
    aspect-ratio: 4 / 5;
    overflow: hidden;
    @media screen and (max-width: 991px) {
      aspect-ratio: 16 / 9;
    }
    @media screen and (max-width: 767px) {
      aspect-ratio: 16 / 8;
    }
  }
  &__titulo {
    font-size: 1.2rem;
    position: relative;
    z-index: 2;
    text-align: center;
    @media screen and (max-width: 575px) {
      font-size: 1.4rem;
      font-weight: $font-weight-bold;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    padding: $font-size;
    color: #fff !important;
    width: 100%;

    &::before {
      content: "";
      background: -webkit-linear-gradient(
        bottom,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      height: 6rem;
      position: absolute;
      z-index: 1;
    }
  }

  &__figure {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.destacados {
  padding: 2rem 0 3rem 0;
  @media screen and (max-width: 575px) {
    padding: 3rem 0 3rem 0;
  }

  &__container {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 767px) {
      max-width: 100%;
      width: 100%;
      margin: 0;
    }
  }

  ul.products {
    @media screen and (max-width: 575px) {
      margin-top: 0rem;
    }
  }
  &__container {
  }
  &__content {
  }
}

.home ul.products.columns-3 {
  display: block !important;
  li.product {
    margin: 0 1rem;
    @media screen and (max-width: 575px) {
      margin: 0 0.5rem;
    }
  }
}

.slick-slider {
  cursor: url("../assets/ico/cursor-scroll.png"), auto;
  a {
    cursor: url("../assets/ico/cursor-scroll.png"), auto;
  }
  .slick-track {
    padding-top: 1rem;
  }
  .slick-prev,
  .slick-next {
    width: 3rem;
    height: 3rem;
    top: 40%;
    z-index: 2;

    &::before {
      color: $color-gris-03;
      font-size: 3rem !important;
      background-color: $color-background;
      border-radius: 50px;
    }
  }
}

.cat-home {
  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 3rem;
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
      gap: 1px;
    }
  }
  &__item {
    height: 700px;
    background-color: $color-gris-01;
    position: relative;
    display: block;
    @media screen and (max-width: 1200px) {
      height: 500px;
    }
    @media screen and (max-width: 991px) {
      height: 350px;
    }
    @media screen and (max-width: 575px) {
      height: 220px;
    }
    &::after {
      content: "";
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      height: 6rem;
      position: absolute;
      z-index: 2;
    }
  }
  &__background {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    z-index: 1;
    position: relative;
  }
  &__description {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    z-index: 3;
    color: $color-background;
    font-size: 1.5rem;
    font-weight: 600;
    @media screen and (max-width: 575px) {
      padding: 1rem;
      align-items: center;
      justify-content: flex-end;
    }
    h2 {
      font-size: 1.9rem;
      @media screen and (max-width: 575px) {
        font-size: 2.1rem;
        font-weight: $font-weight-bold;
        margin-bottom: 0;
      }
    }
    span {
      font-size: $font-size;
      @media screen and (max-width: 575px) {
        font-size: $font-size-small;
      }
    }
  }
}

.index-press {
  padding: 4rem 0;
  text-align: center;
  background-color: $color-complementario-amarillo;
  margin-bottom: 2px;
  &__container {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 991px) {
      width: 90%;
    }
    @media screen and (max-width: 767px) {
      width: 100;
    }
  }

  &__empresa {
    text-transform: uppercase;
    margin-bottom: 1rem;
    display: inline-block;
    color: $font-size-small;
  }

  &__h1 {
    font-size: 1.9rem;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 767px) {
      font-size: 1.5rem;
    }
  }

  &__descripcion {
    margin-bottom: 3rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__h2 {
    font-size: 1.3rem;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 575px) {
      font-size: 1.1rem;
    }
  }
}

.marcas {
  &__content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  &__item {
    padding: 5px 10px;
  }
}
