.page-template-page-login.logged-out .bg-login {
  background: url("../assets/site/bg-login.jpg") center center;
  background-size: cover;
}

.logged-out.page-template-page-login .woocommerce > h2 {
  display: none;
}

.logged-out.page-template-page-login {
  .u-columns {
    .col-1,
    .col-2 {
      > h2 {
        color: $color-background;
        text-align: center;
        text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
        margin-bottom: 1rem;
      }
    }
  }
}

.woocommerce-form-register,
.woocommerce-form-login {
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem 2rem;
  background-color: $color-background;
  box-shadow: $box-shadow;
  .woocommerce-privacy-policy-text {
    p {
      font-size: $font-size-smaller !important;
    }
  }
}

.woocommerce-form-register,
.woocommerce-form-row,
.woocommerce-form-login {
  label {
    display: block;
    margin-bottom: 8px;
    font-size: 0.9rem;
  }

  input {
    display: block;
    width: 100%;
  }

  &__rememberme {
    padding-bottom: 1rem !important;
  }

  &__submit {
    display: block;
    width: 100%;
  }

  .lost_password {
    font-size: $font-size-smaller;
    text-align: right;
  }
}

.page-template-page-login.logged-in .woocommerce {
  display: grid;
  grid-template-columns: 3fr 9fr 1fr;
  grid-gap: 3rem;

  @media (max-width: 991px) {
    display: initial;
  }
}

.woocommerce-MyAccount-navigation {
  ul {
    // @include card-cart;
    list-style: none;

    @media (max-width: 991px) {
      display: flex;
      padding: 0;
      background-color: initial;
      margin-top: 0;
      flex-wrap: wrap;
    }
  }

  li {
    margin-right: 1rem;
    @media screen and (max-width: 991px) {
      margin-bottom: 3px;
    }
  }

  li a {
    @media (max-width: 991px) {
      padding: 5px 1rem;
      background-color: $color-gris-02;
      border-radius: 50px;
      color: $color-background;
      display: inline-flex;
    }

    @media (max-width: 767px) {
      font-size: $font-size-smaller;
      color: $color-gris-03;
      color: $color-background;
    }
  }
  li.is-active a {
    color: $color-gris-03;
    @media (max-width: 991px) {
      font-size: $font-size-smaller;
      color: $color-background;
      background-color: $color-alternativo;
    }
  }
}

.woocommerce-MyAccount-content {
  .woocommerce-table--order-details {
    display: block;
  }
  .woocommerce-Message .button {
    display: inline-block;
  }
}

.woocommerce-EditAccountForm {
  .woocommerce-form-row input {
    width: auto;
  }

  span em {
    font-size: $font-size-smaller;
  }
}

.account-orders-table {
  font-size: 0.9rem;
  border-collapse: collapse;
  thead {
    @media (max-width: 767px) {
      display: none;
    }
  }
  tbody {
    @media (max-width: 767px) {
      font-size: $font-size-smaller;
    }
    tr {
      @media (max-width: 767px) {
        display: block;
        display: flex;
        flex-direction: column;
        background-color: $color-background;
        margin-bottom: 1rem;
        padding: 1rem;
        border-radius: $border-radius;
      }
    }
    td {
      text-align: center;
      padding: 10px 5px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-left: 0;
      border-right: 0;
      @media (max-width: 767px) {
        text-align: right;
        padding: 8px 5px;
      }
    }

    .view {
      padding: 6px 1rem;
      display: block;
    }
  }

  .woocommerce-orders-table__cell-order-status {
    font-weight: $font-weight-bold;
  }
  .woocommerce-orders-table__cell-order-actions .view {
    display: inline-block;
  }
}

div[data-title="Cancelado"] {
  color: rgb(118, 14, 14);
}

// .logged-out.page-template-page-login .woocommerce>h2 {
//   display: none;
// }

// .woocommerce-form-login {
//   // @include card-cart;
//   max-width: 400px;
//   margin: 0 auto;
// }

// .woocommerce-form-row,
// .woocommerce-form-login {
//   label {
//     display: block;
//     margin-bottom: 8px;
//     font-size: 0.9rem;
//   }

//   input {
//     display: block;
//     width: 100%;
//   }

//   &__rememberme {
//     padding-bottom: 1rem !important;
//   }

//   &__submit {
//     display: block;
//     width: 100%;
//   }

//   .lost_password {
//     font-size: $font-size-smaller;
//     text-align: right;
//   }
// }

// .page-template-page-login.logged-in .woocommerce {
//   display: grid;
//   grid-template-columns: 3fr 9fr 1fr;
//   grid-gap: 3rem;

//   @media (max-width: 991px) {
//     display: initial;
//   }
// }

// .woocommerce-MyAccount-navigation {

//   ul {
//     // @include card-cart;
//     list-style: none;

//     @media (max-width: 991px) {
//       display: flex;
//       padding: 0;
//       background-color: initial;
//       margin-top: 0;
//       flex-wrap: wrap;
//     }
//   }

//   li {
//     margin-right: 1rem;
//     @media screen and (max-width: 991px) {
//       margin-bottom: 3px;
//     }
//   }

//   li a {
//     @media (max-width: 991px) {
//       padding: 5px 1rem;
//       background-color: $color-gris-02;
//       border-radius: 50px;
//       color: $color-background;
//       display: inline-flex;
//     }

//     @media (max-width: 767px) {
//       font-size: $font-size-smaller;
//       color: $color-gris-03;
//       color: $color-background;
//     }
//   }
//   li.is-active a {
//     color: $color-gris-03;
//     @media (max-width: 991px) {
//       font-size: $font-size-smaller;
//       color: $color-background;
//       background-color: $color-alternativo;
//     }
//   }
// }

// .woocommerce-MyAccount-content {
//   .woocommerce-table--order-details {
//     display: block;
//   }
//   .woocommerce-Message .button {
//     display: inline-block;
//   }
// }

// .woocommerce-EditAccountForm {
//   .woocommerce-form-row input {
//     width: auto;
//   }

//   span em {
//     font-size: $font-size-smaller;
//   }
// }

// .account-orders-table {
//   font-size: .9rem;
//   border-collapse: collapse;
//   thead {
//     @media (max-width: 767px) {
//       display: none;
//     }
//   }
//   tbody {
//     @media (max-width: 767px) {
//       font-size: $font-size-smaller;
//     }
//     tr {
//       @media (max-width: 767px) {
//         display: block;
//         display: flex;
//         flex-direction: column;
//         background-color: $color-background;
//         margin-bottom: 1rem;
//         padding: 1rem;
//         border-radius: $border-radius;

//       }
//     }
//     td {
//       text-align: center;
//       padding: 10px 5px;
//       border-bottom: 1px solid rgba(0, 0, 0, 0.1);
//       border-left: 0;
//       border-right: 0;
//       @media (max-width: 767px) {
//         text-align: right;
//         padding: 8px 5px;
//       }
//     }

//     .view {
//       padding: 6px 1rem;
//       display: block;
//     }
//   }

//   .woocommerce-orders-table__cell-order-status {
//     font-weight: $font-weight-bold;
//   }
//   .woocommerce-orders-table__cell-order-actions .view {
//     display: inline-block;
//   }
// }

// div[data-title="Cancelado"] {
//   color: rgb(118, 14, 14);
// }
