.page-title {
  padding-top: 3rem;
  padding-top: 2rem;
  overflow-y: auto;
  @media screen and (max-width: 575px) {
    padding-top: 0.5rem;
  }
  &__h {
    margin-bottom: 2rem;
    font-size: 2.5rem;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 575px) {
      margin-bottom: 1.5rem;
      font-size: 1.5rem;
    }
    @media screen and (max-width: 360px) {
      margin-bottom: 0.5rem;
      font-size: 1.8rem;
    }
  }
  &__h2 {
  }
  &__breadcrumbs {
    margin-bottom: $font-size / 2;
    font-size: $font-size-small;
    color: $color-gris-02;
    @media screen and (max-width: 575px) {
      font-size: 0.6rem;
      margin-bottom: 5px;
    }
    a {
      // font-weight: 500;
      color: $color-gris-02;
    }
    strong {
      font-weight: 400;
      color: $color-secundario;
    }
  }
}

.single-product .page-title {
  padding-top: 2rem;
  @media screen and (max-width: 575px) {
    padding-top: 0.5rem;
  }
}

.page-id-9 .page-title {
  display: none;
}

.h-estilos {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  &__cover {
    flex: 1 0 50%;

    img {
      width: 100%;
      height: auto;
      border-radius: $border-radius;
    }
  }

  &__desc {
    flex: 1 0 40%;
  }
}
