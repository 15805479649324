$color-principal-light: #fdd;
$color-principal: #ff0066;
$color-principal-dark: #6c3e51;

$color-secundario: #71a3bf;
$color-secundario-dark: darken(#71a3bf, 20%);

$color-accent: #fda11e;
$color-accent-dark: #6e4e22;
$color-alternativo: $color-accent;

$color-complementario-rosa: $color-principal-light;
$color-complementario-amarillo: #fff1dd;

$color-whatsapp: #128c7e;

$color-gris-01: #f4f4f4;
$color-gris-02: #707070;
$color-gris-03: #4d4d4d;
$color-gris-04: #252a32;

$color-grey-1: #262626;
$color-grey-2: #424142;
$color-grey-3: #7f7f7f;
$color-grey-4: #b6b6b6;
$color-grey-5: #d7d7d7;
$color-grey-6: #f5f5f5;

$color-white: #fff;
$color-background: $color-white;

$font-size-big: 1.2rem;
$font-size: 0.9rem;
$font-size-small: 0.8rem;
$font-size-smaller: 0.7rem;

$font-weight: 400;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;

$box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
$box-shadow-hover: 0 0 20px 0 rgba(0, 0, 0, 0.05);

$border-radius: $font-size / 2;

$trans: 0.2s all ease-in-out;

$sp-1: 3rem;
$sp-2: 6rem;
$sp-3: 10rem;

$typo-principal: "Work Sans", sans-serif;
$typo-secundaria: "Work Sans", sans-serif;
$typo-condensed: "Oswald", sans-serif;

$space-0: 0px;
$space-05: 0.5rem;
$space-01: 1rem;
$space-1: 1.5rem;
$space-02: 2rem;
$space-2: 2.5rem;
$space-3: 4rem;
$space-4: 5rem;
$space-45: 112px;
$space-5: 7.5rem;
$space-6: 9.375rem;

$header-1: 3rem;
$header-1-res: 2.5rem;
$header-2: 2.25rem;
$header-2-res: 30px;
$header-3: 24px;
$header-3-res: 1.25rem;
$header-4: 1.25rem;
$header-5: 1rem;
$header-6: 0.87rem;

:root {
  --app-height: 100%;
}

//  Puffin Display Soft Medium
// font-family: puffin-display-soft, sans-serif;
// font-weight: 500;
// font-style: normal;

// Puffin Display Soft Medium Italic
// font-family: puffin-display-soft, sans-serif;
// font-weight: 500;
// font-style: italic;

// Puffin Display Soft Regular
// font-family: puffin-display-soft, sans-serif;
// font-weight: 400;
// font-style: normal;
// Puffin Display Soft Bold Italic

// font-family: puffin-display-soft, sans-serif;
// font-weight: 700;
// font-style: italic;

// Puffin Display Soft SemiBold Italic
// font-family: puffin-display-soft, sans-serif;
// font-weight: 600;
// font-style: italic;

// Puffin Display Soft Italic
// font-family: puffin-display-soft, sans-serif;
// font-weight: 400;
// font-style: italic;

// Puffin Display Soft SemiBold
// font-family: puffin-display-soft, sans-serif;
// font-weight: 600;
// font-style: normal;

// Puffin Display Soft Bold
// font-family: puffin-display-soft, sans-serif;
// font-weight: 700;
// font-style: normal;
