// Grid -------------------------------

div.product {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-auto-rows: auto;
  column-gap: 2.5rem;
  row-gap: 5rem;
  position: relative;
  @media (max-width: 767px) {
    display: block;
  }

  .woocommerce-product-gallery {
    grid-column: 1 / 6;
    grid-row: 1 / 2;
    position: relative;
  }

  .summary {
    grid-column: 6 / 10;
    grid-row: 1 / 2;
    @media screen and (max-width: 1199px) {
      grid-column: 6 / 11;
    }
  }

  .woocommerce-tabs {
    grid-column: 1 / 6;
    grid-row: 2 / 3;
  }

  .related {
    grid-column: 6 / 11;
    grid-row: 2 / 3;
  }

  .up-sells {
    grid-column: 1 / 11;
    grid-row: 3 / 4;
  }
  .onsale {
    background-color: $color-principal;
    position: absolute;
    display: inline-block;
    font-family: $typo-condensed;
    text-transform: uppercase;
    font-weight: bold !important;
    z-index: 50;
    top: 2rem;
    left: 2rem;
    color: $color-background;
    padding: 5px 10px;
    border-radius: 50px;
    @media screen and (max-width: 575px) {
      top: 1rem;
      left: 1rem;
    }
  }
}

.summary {
  @media (max-width: 767px) {
    margin-bottom: 3rem !important;
  }
  @media screen and (max-width: 575px) {
    margin-bottom: 3rem;
  }

  .product_title {
    margin-top: 0;
    padding-top: 0;
    color: $color-gris-04;
    font-size: 1.5rem !important;
    font-weight: $font-weight-bold;
    margin-bottom: 0rem;
    color: $color-gris-04;

    @media (max-width: 575px) {
      font-size: 1.4rem !important;
      margin-bottom: 0.3rem;
    }
  }

  .price {
    font-size: 1.5rem !important;

    @media (max-width: 575px) {
      font-size: 1.2rem !important;
    }
  }

  ins {
    text-decoration: none !important;
    bdi {
      color: red;
    }
  }
  del {
    margin-right: 0.5rem;
    bdi {
      font-size: 80%;
    }
  }

  .stock {
    &.out-of-stock {
      color: red;
      font-weight: $font-weight-bold;
    }
  }

  .screen-reader-text {
    display: none;
  }

  .cart.variations_form {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
  }

  .cart {
    margin-bottom: 2rem;
    display: flex;
  }

  .cart .qty {
    padding: 15px 10px;
    font-size: 1rem;
    text-align: center;
    box-shadow: none;
    @media (max-width: 575px) {
      padding: 10px 5px;
      width: 80px;
    }
  }

  .quantity {
    margin-right: 1rem;
    &.hidden {
      display: none;
    }
  }

  .single_add_to_cart_button {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .woocommerce-variation-add-to-cart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.ncdgw_textarea_wrapper {
  margin-top: 0.5rem;
}

// Titulo y Summary -------------------------------

.product_meta {
  font-size: 0.8rem;
  margin-bottom: 1rem;
  display: none;
  @media screen and (max-width: 767px) {
    display: none;
  }
  & > span {
    display: block;
  }
  a {
    color: $color-gris-04;
    text-decoration: underline;
  }
}

.summary {
  .price {
    margin: 0 0 1rem;
    font-weight: normal;
    color: $color-gris-04;
    font-size: 2rem;

    @media (max-width: 575px) {
      border: none;
      margin: 0 0 1rem;
      padding: 0;
    }

    @media (max-width: 390px) {
      margin: 0 0 0.5rem;
    }
  }

  .woocommerce-product-details__short-description {
    margin-bottom: 1.4rem;
    font-size: $font-size;
    color: $color-gris-03;
  }

  table.variations {
    // margin-bottom: 1rem;

    label {
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      font-size: $font-size-smaller;
      letter-spacing: 1px;
      color: $color-gris-04;
    }

    .reset_variations {
      font-size: 12px;
      color: red;
    }
  }
}

.guia-tallas {
  margin-bottom: 10px;
  &__link {
    cursor: pointer;
    @media screen and (max-width: 575px) {
      font-size: $font-size;
    }
  }
}

.cinta {
  background-color: rgba($color: #000000, $alpha: 0.02);
  padding: 0rem 0rem;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  overflow: hidden;
  border: 1px solid rgba($color: #000000, $alpha: 0.2);
  &__ico {
    margin-right: 2rem;
    img {
      width: 70px;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 90px;
      }
    }
  }
  &__content {
    padding-right: 1rem;
  }
}

// Agrupados Colecciones -------------------------------
.coleccion {
  margin-bottom: 2rem;

  &__item {
    display: grid;
    grid-template-columns: 60px 50px auto 70px;
    grid-gap: 1rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 4px;
  }

  &__foto img {
    width: 50px;
    height: auto;
    border-radius: 2px;
  }

  &__quantity label {
    display: none;
  }

  &__quantity .quantity input {
    width: 100%;
    padding: 0.75rem 0.5rem !important;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: $color-gris-03;
    min-width: 50px;
  }

  &__nombre .precio-metro {
    font-size: 0.8rem;
    background-color: $color-gris-02;
    color: rgba(0, 0, 0, 0.6);
    padding: 1px 4px;
    border-radius: 3px;
  }

  &__label label {
    display: flex;
    margin-bottom: 3px;
  }

  &__label label a {
    font-size: 13px;
    font-weight: 400;
    color: $color-gris-04;
  }

  &__price {
    text-align: right;
    font-size: 1rem;
    color: $color-principal;
    font-weight: 700;

    .stock {
      margin: 0;
      font-size: $font-size-small;
      color: $color-gris-03;
    }

    .out-of-stock {
    }
  }
}

.garantia-extras {
  &__nav {
  }

  &__item {
  }
}

.garantia-extras {
  font-size: $font-size-small;
  @media screen and (max-width: 575px) {
    // margin-bottom: 1rem!important;
  }

  a {
    color: $color-gris-04;
    border-bottom: 1px solid;
    font-weight: normal;
  }

  &__nav {
    list-style: none;
    padding-left: 0;
  }
  &__item {
    margin-bottom: 8px;
    @media (max-width: 575px) {
      margin-bottom: 6px;
    }
    .bi {
      margin-right: 5px;
    }

    &--share {
      padding-bottom: 8px;
      a {
        border-bottom: 0;
        font-weight: $font-weight-bold;
        color: $color-whatsapp;
      }
    }
  }

  a.verde-whatsapp {
    color: $color-whatsapp;
    @media (max-width: 575px) {
      background-color: $color-whatsapp;
      color: $color-background;
      display: inline-block;
      border-radius: 50px;
      padding: 0 8px;
      text-decoration: none;
    }
  }
}

// TABS -------------------------------

.woocommerce-tabs {
  @media (max-width: 767px) {
    margin-bottom: $sp-2;
  }

  @media screen and (max-width: 575px) {
    background-color: $color-gris-01;
    padding-top: 2rem;
    padding-bottom: 1rem;
    padding-left: 12px;
    padding-right: 12px;
    margin-left: -11px;
    margin-right: -11px;
  }
  .wc-tab {
    // padding: 2rem;
    padding-top: 1rem;
    border-radius: $border-radius;
    @media screen and (max-width: 575px) {
      padding-top: 1rem;
    }
    & > div {
      // font-family: $typo-secundaria;
    }
    h2,
    h3,
    h4,
    ul,
    ol {
      margin-bottom: 1.5rem;
    }
    h2 {
      font-size: 1.5rem;
    }
    h3 {
      font-size: 1.3rem;
    }
    p {
      margin-bottom: 1.2rem;
      // br {
      //   margin-bottom: 1.2rem;
      // }
    }

    h2:first-child {
      margin-top: 0;
      font-size: 1rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
}

.woocommerce-tabs .tabs {
  padding-left: 0;
  list-style: none;
  display: flex;
  @media screen and (max-width: 575px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.3rem;
  }
  li {
    @media screen and (min-width: 576px) {
      margin-right: 0.3rem;
    }
  }

  li a {
    // @include tabs-title;
    display: block;
    position: relative;
    padding: 4px 8px;
    background-color: rgba($color: #000000, $alpha: 0.2);
    color: $color-gris-04;
    border-radius: 4px;
    @media screen and (max-width: 575px) {
      text-align: center;
    }
    @media screen and (max-width: 380px) {
      padding: 4px 6px !important;
      font-size: 0.75rem;
    }

    &:hover {
      background-color: $color-gris-02;
      color: $color-background;
    }
  }

  li.active a {
    color: $color-background;
    background-color: $color-gris-03;
    position: relative;
  }
  // .misha_custom_tab_tab a {
  //   background-color: $color-principal;
  //   color: $color-background;
  // }
}

.comment-form-email,
.comment-form-author {
  label {
    display: block;
  }
}

.wp-comment-cookies-consent {
  label {
    font-size: $font-size-smaller !important;
  }
}

.woocommerce-product-attributes {
  width: 100%;
  &-item {
    @media screen and (max-width: 575px) {
      font-size: 0.8rem;
    }
    &__label {
      background-color: $color-gris-01;
      width: 25%;
      text-align: right;
      padding-right: 1rem;
      @media screen and (max-width: 575px) {
        padding-right: 0;
        text-align: center;
      }
    }
    &__value {
      padding-left: 1rem;
      background-color: rgba(0, 0, 0, 0.05);
      @media screen and (max-width: 575px) {
        padding-left: 0;
        text-align: center;
      }
    }
  }
}

// Product Gallery -------------------------------
.woocommerce-product-gallery {
  position: relative;
  margin-bottom: 1rem;

  &__trigger {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 10;
  }

  &__wrapper {
    min-width: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    // grid-auto-flow: column;
    // grid-auto-columns: minmax(0, 1fr);

    gap: $font-size-smaller / 4;
  }

  &__image {
    margin: 0;
    padding: 0;
    overflow: hidden;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    aspect-ratio: 1 / 1;
    img {
      width: 100% !important;
      max-width: 100% !important;
      height: auto;
    }
    &:first-child {
      width: 100%;
      display: block;
      margin-right: 0;
      grid-column: 1 / -1;
    }
  }
  .wp-post-image {
    width: 100% !important;
    height: auto !important;

    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.single-product.woocommerce div.product .images figure [data-thumb] {
  // width: 100% !important;
  // float: none !important;
}

.x-container {
  display: flex;
  overflow-y: auto;
  // &__gallery {
  //   flex: 0 0 70px;
  // }
  // &__picture {
  //   flex: 0 0 80%;
  // }
}

// Relacionados -------------------------------

.products.related > h2,
.products.upsells > h2 {
  // @include related-header;
  // border-bottom: 1px solid #666;
  margin-bottom: 1.2rem !important;
  font-size: 1.2rem;
}

// Relacionados -------------------------------
section.related ul.products {
  display: grid;
  grid-template-columns: 1fr 1fr;

  li.product {
    // border-bottom: 1px solid $color-gris-02;);
    display: block;
    width: 100%;
    height: auto;
    clear: both;
    // text-align: center;
  }

  .attachment-woocommerce_thumbnail {
    float: left !important;
    width: 100% !important;
    height: auto;
    margin-right: 1rem;
  }

  .woocommerce-loop-product__link {
    width: 100%;
    align-items: inherit;
    display: block !important;
    // margin-bottom: 0;
    height: auto;
    // overflow: hidden;
  }

  .product__cat {
  }

  .woocommerce-loop-product__title {
    font-size: 0.9rem;
    width: auto;
  }

  .price {
  }
}

// Garantia -------------------------------

.garantia {
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  background-color: rgba(0, 0, 0, 0.04);
  margin-bottom: 1rem;
  border-radius: 5px;
  @media screen and (max-width: 767px) {
    margin-top: $font-size;
  }

  &__item {
    display: flex;
    align-items: center;
    // text-transform: uppercase;
    font-size: $font-size-small;
    flex: 1 0 30%;
    @media screen and (max-width: 767px) {
      font-size: 0.7rem;
      line-height: 1.2;
    }
  }

  &__ico {
    margin-right: 10px;
    .bi {
      font-size: 1.5rem;
      color: $color-gris-03;
      color: $color-principal-dark;
    }
  }

  &__desc {
  }
}

.opciones {
  margin-bottom: 1rem;
  &__item {
    margin-bottom: 5px;
    &--tiempo {
      background-color: #aefcd7;
      color: $color-gris-03;
      display: inline-block;
      padding: 1px 10px;
      border-radius: 4px;
    }
    &--kit {
      span {
        padding-left: 10px;
      }
    }
  }
}

.descargas {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  @media screen and (max-width: 575px) {
    gap: 6px;
  }
  &__item {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: $color-gris-02;
    color: $color-background;
    font-size: $font-size-small;
    @media screen and (max-width: 575px) {
      font-size: $font-size-smaller;
      padding: 4px 8px;
    }
    .bi {
      padding-right: 5px;
      @media screen and (max-width: 575px) {
        padding-right: 3px;
      }
    }
    &:hover {
      background-color: $color-gris-03;
      color: $color-background;
    }
  }
}

// Prodcut Reviews -------------------------------
.woocommerce-Reviews {
  .woocommerce-Reviews-title {
  }

  .woocommerce-noreviews {
  }
}

#review_form_wrapper {
}

#review_form {
  .comment-reply-title {
    font-size: $font-size-smaller;
    margin-bottom: 1rem;
    display: block;
  }

  .comment-notes {
  }
}

// Prodcut Reviews -------------------------------
.product .star-rating span:before,
.star-rating::before {
  color: $color-principal !important;
  border-color: $color-principal !important;
}

.woocommerce-product-rating .woocommerce-review-link {
  font-size: $font-size-smaller;
  color: $color-gris-04;
}

.presupuesto {
  padding: 1.5rem;
  background-color: rgb(247, 247, 231);
  margin-bottom: 1rem;
  &__titulo {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: $font-weight-bold;
  }
  .wpcf7 {
    margin-bottom: 0;
    textarea {
      height: 5em;
    }
  }
}
