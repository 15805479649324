// CART ---

.wc-block-cart-item {
  &__quantity {
    display: flex;
    align-items: center;
    gap: $font-size;
  }
}

// CHECKOUT ---------------

.wc-block-checkout {
  &__terms {
    font-size: $font-size-smaller;
    a {
      text-decoration: underline;
    }
  }
}

.wc-block-components-validation-error {
  p {
    font-size: $font-size-smaller !important;
  }
}

.wc-block-components-radio-control {
  &__option-checked {
    .wc-block-components-radio-control__label {
      color: $color-principal;
    }
  }
  &__label {
  }
  &__input {
  }
  .wc-block-components-radio-control-accordion-content {
    font-size: $font-size-small;
  }
}

.wc-block-components-checkout-step {
  &__title {
    color: $color-principal-dark;
  }
}

// Empty cart
.wp-block-woocommerce-empty-cart-block {
  .wc-block-cart__empty-cart__title {
    border: dashed 1px $color-grey-4;
    background-color: $color-grey-6;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $space-1;
    gap: $space-1 !important;
    @media screen and (max-width: 575px) {
      gap: 0px !important;
    }
  }
  h2 {
    @media screen and (max-width: 575px) {
      font-size: $font-size;
    }
  }
  h2:before {
    background-color: $color-grey-4 !important;
    margin: 0 !important;
    width: 80px !important;
    mask-size: 80px !important;
    @media screen and (max-width: 575px) {
      width: 50px !important;
      mask-size: 40px !important;
      margin-left: 1rem !important;
    }
  }
  p {
    color: $color-white;
    a {
      display: inline-block;
      padding: 0.5rem 2rem;
      background-color: $color-principal;
      border-radius: 100px;
      color: $color-white;
      font-size: $font-size-small;
      @media screen and (min-width: 575px) {
        margin-bottom: $space-2;
      }
    }
  }
  .wp-block-separator {
    display: none;
  }
}

.wc-block-grid {
  &__products {
    @media screen and (max-width: 575px) {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
    }
  }
}

.wp-block-woocommerce-cart-cross-sells-block {
  @media screen and (max-width: 575px) {
    margin-top: $space-1;
  }
  h2 {
    @media screen and (max-width: 575px) {
      font-size: $header-4 !important;
    }
  }
  div {
    @media screen and (max-width: 575px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: $space-1;
    }
  }
}

.cross-sells-product {
  div {
    img {
      @media screen and (max-width: 575px) {
        margin-bottom: calc($space-1/2);
      }
    }
    @media screen and (max-width: 575px) {
      display: inline;
    }
  }
}

.wc-block-components-product-title {
  margin-bottom: calc($space-1/2) !important;
  color: $color-principal !important;
}

// Buttons

// wc pos -----------------------------

#div-redsys input[type="button"],
.wc-block-components-checkout-place-order-button,
.wc-block-components-button {
  background-color: $color-principal;
  border-color: $color-principal;
  color: $color-white !important;
  border-radius: $border-radius;
  font-size: $font-size-big;
  font-weight: $font-weight-medium;
  transition: $trans;
  &:hover {
    background: $color-principal-dark;
    color: $color-white;
    transition: $trans;
  }
}

// Store notice
.woocommerce-store-notice.demo_store {
  text-align: center;
  position: fixed;
  bottom: 0;
  background: $color-grey-3;
  color: $color-white;
  font-size: $font-size-smaller;
  width: 100%;
  padding: $font-size-smaller;
  margin-bottom: 0;
  a {
    text-decoration: underline;
    color: $color-white;
  }
}
