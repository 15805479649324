.footer {
  padding: 4rem 0;
  background-color: rgba($color: #000000, $alpha: 0.05);
  background-color: $color-principal-light;
  @media screen and (max-width: 767px) {
    padding: 2rem 0;
  }
  &__prefooter {
    margin-bottom: 4rem;
    display: flex;
    @media screen and (max-width: 991px) {
      flex-wrap: wrap;
    }
    @media screen and (max-width: 575px) {
      margin-bottom: 2rem;
    }
  }

  &__logo {
    margin-right: 2rem;
    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
    }
    img {
      width: 200px;
      height: auto;
      @media screen and (max-width: 767px) {
        height: 34px;
        width: auto;
      }
    }
  }

  &__social {
    display: flex;
    align-items: center;
    margin-right: 4rem;
    @media screen and (max-width: 767px) {
      margin-right: 0;
    }
  }

  &__financiacion {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: $font-size;
    @media screen and (max-width: 991px) {
      margin-top: 2rem;
    }
    img {
      max-width: 500px !important;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 100% !important;
      }
    }
  }

  &__container {
    margin-bottom: 4rem;
    @media screen and (max-width: 767px) {
      margin-bottom: 2rem;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    width: 100%;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
  }
  &__1 {
    margin-bottom: 1rem;
    @media screen and (max-width: 767px) {
      grid-column: 1 / 3;
    }
    img {
      width: 200px;
      height: auto;
    }
  }
  &__2 {
  }
  &__3 {
  }
  &__4 {
    @media screen and (max-width: 767px) {
      grid-column: 1 / 3;
    }
  }

  &__copy {
    text-align: center;
    p {
      @media screen and (max-width: 767px) {
        margin-bottom: 5px;
      }
    }
  }
}

.compres {
  padding: 2rem 0;
  background-color: $color-gris-01;
  margin-bottom: 2px;
  @media screen and (max-width: 575px) {
    padding: 1rem 0;
  }
  &__container {
  }

  &__content {
    display: flex;
    justify-content: space-around;
    @media screen and (max-width: 575px) {
      flex-direction: column;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem 1.5rem;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    @media screen and (max-width: 575px) {
      align-items: flex-center;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__icono {
    margin-right: 1.2rem;
    @media screen and (max-width: 575px) {
      margin-right: 0.8rem;
      flex: 0 0 10%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
    .bi {
      font-size: 2rem;
    }
  }

  &__titulo {
    font-weight: 500;
    font-size: $font-size-small;
    @media screen and (max-width: 575px) {
      font-weight: 600;
      line-height: 1.2;
      font-size: 0.7rem;
    }
    span {
      display: block;
      color: $color-gris-02;
      font-weight: normal;
      @media screen and (max-width: 575px) {
        margin-top: 5px;
      }
    }
  }
}
